import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { BBSchema } from '../commonSchemas';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

export const relatedDocumentSchema = z
  .object({
    local_document_id: z.string(),
    local_bbs: z.array(BBSchema).nullable().default(null),
    local_page_number: z.number().nullable().default(null),
    remote_document_id: z.string(),
    remote_legal_id: z.string().nullable().default(null),
    remote_title: z.string(),
    remote_tag: z.string(),
    remote_bbs: z.array(BBSchema).nullable().default(null),
    remote_page_number: z.number().nullable().default(null),
    reference_type: z.enum(['related', 'outgoing_mention', 'incoming_mention']),
    is_active: z.boolean(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type RelatedDocument = z.infer<typeof relatedDocumentSchema>;

const relatedDocumentsSchema = z
  .object({
    refs: z.array(relatedDocumentSchema),
    total_count: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type RelatedDocuments = z.infer<typeof relatedDocumentsSchema>;

export type Tags =
  | 'Rättspraxis'
  | 'Författning'
  | 'Förarbete'
  | 'EU Sekundärrätt'
  | 'EU Rättspraxis'
  | 'Case Law'
  | 'Primary Law'
  | 'Secondary Law'
  | 'EBA'
  | 'ESMA';

async function getDocumentRelations(
  documentId: string,
  relationType?: 'related' | 'outgoing_mention' | 'incoming_mention',
  tags?: Tags[],
  pageNumber?: number,
  limit?: number,
  offset?: number,
  query?: string,
): Promise<RelatedDocuments> {
  if (!documentId) {
    return { refs: [], totalCount: 0 };
  }

  try {
    const token = await getAuthToken();
    const response = await axios.post(
      `${REST_API_URL}/database/document/${documentId}/related`,
      {
        relation_type: relationType,
        tags: tags,
        page_number: pageNumber,
        limit: limit,
        offset: offset,
        query: query,
      },
      standardAxiosOptions(token),
    );
    return relatedDocumentsSchema.parse(response.data);
  } catch (error) {
    alertError(
      error,
      { function: 'getDocumentRelations' },
      { documentId, relationType, tags, pageNumber, limit, offset, query },
    );
    throw error;
  }
}

export const useDocumentRelations = (
  documentId: string,
  relationType?: 'related' | 'outgoing_mention' | 'incoming_mention',
  tags?: Tags[],
  pageNumber?: number,
  limit?: number,
  offset?: number,
) => {
  return useQuery({
    queryKey: ['document_relations', documentId, relationType, tags, pageNumber, limit, offset],
    queryFn: () => getDocumentRelations(documentId, relationType, tags, pageNumber, limit, offset),
    retry: false,
  });
};

export const useDocumentRelationTags = (documentId: string, tags: Tags[]) => {
  return useQueries({
    queries: tags.map((tag) => ({
      queryKey: ['document_relation_tag', documentId, tag],
      queryFn: () =>
        getDocumentRelations(documentId, undefined, [tag], undefined, 1, 0, undefined).then(
          (res) => res.totalCount > 0,
        ),
    })),
  });
};

export const useInfiniteDocumentRelations = (
  documentId: string,
  relationType?: 'related' | 'outgoing_mention' | 'incoming_mention',
  tags?: Tags[],
  pageSize: number = 10,
) => {
  return useInfiniteQuery({
    queryKey: ['document_relations', documentId, relationType, tags, pageSize],
    queryFn: ({ pageParam = 0 }) =>
      getDocumentRelations(
        documentId,
        relationType,
        tags,
        undefined,
        pageSize,
        pageParam * pageSize,
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length;
      return nextPage * pageSize < lastPage.totalCount ? nextPage : undefined;
    },
  });
};
