import { MAX_CHUNKS_PER_DOCUMENT, MAX_DOCUMENTS_PER_SEARCH } from '../../constants';
import { useSearchStore } from '../searchStore';
import {
  getCurrentAvailableFilterTags,
  transformTagSelection,
} from '@/services/api/actions/useAvailableFilterTags';
import { SearchParams, SearchResult } from '@/services/api/commonSchemas';

export const setQuery = (query: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.query = query;
      state.currentParams.searchMode.noAiFilter = false;
    },
    false,
    'searches/set-query',
  );
};

export const setParams = (params: SearchParams) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = {
        ...params,
        filter: {
          ...params.filter,
          tagIds: [...params.filter.tagIds].sort(),
        },
      };
    },
    false,
    'searches/set-params',
  );
};

export const setActiveDataRole = (dataRole: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.dataRole = dataRole;
    },
    false,
    'searches/set-active-data-role',
  );
};

export const loadOldSearch = (
  searchId: string,
  result: SearchResult,
  submittedParams: SearchParams,
) => {
  const availableFilterTags = submittedParams.dataRole
    ? getCurrentAvailableFilterTags(submittedParams.dataRole)
    : undefined;

  useSearchStore.setState(
    (state) => {
      state.searchIdHistory.push(searchId);
      state.crossDocumentSearches[searchId] = {
        ws: null,
        submittedParams: {
          ...submittedParams,
          filter: {
            ...submittedParams.filter,
            tagIds: availableFilterTags
              ? transformTagSelection(
                  'leaf-only',
                  submittedParams.filter.tagIds,
                  availableFilterTags,
                )
              : [],
          },
        },
        results: result,
      };
    },
    false,
    'searches/load-old-search',
  );
};

export const setFilterAfterYear = (startYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.afterDate = startYear ? [startYear, 1, 1] : null;
    },
    false,
    'searches/set-filter-start-year',
  );
};

export const setFilterBeforeYear = (endYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.beforeDate = endYear ? [endYear, 12, 31] : null;
    },
    false,
    'searches/set-filter-end-year',
  );
};

export const resetFilter = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = true;
      state.currentParams.filter = {
        tagIds: [],
        documentIds: [],
        afterDate: null,
        beforeDate: null,
      };
    },
    false,
    'searches/reset-filter',
  );
};

export const clearParams = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = {
        query: '',
        maxChunksPerDocument: MAX_CHUNKS_PER_DOCUMENT,
        maxDocuments: MAX_DOCUMENTS_PER_SEARCH,
        searchMode: {
          params: {},
          noAiFilter: false,
          noChunks: false,
        },
        filter: {
          tagIds: [],
          documentIds: [],
          afterDate: null,
          beforeDate: null,
        },
        dataRole: state.currentParams.dataRole,
      };
    },
    false,
    'searches/clear-params',
  );
};

export const addTags = (tagIds: string[]) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.tagIds.push(...tagIds);
      state.currentParams.filter.tagIds.sort();
    },
    false,
    'searches/add-filter-tag',
  );
};

export const removeTags = (tagIds: Set<string> | string[]) => {
  tagIds = Array.isArray(tagIds) ? new Set(tagIds) : tagIds;
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.tagIds = state.currentParams.filter.tagIds.filter(
        (tagId) => !tagIds.has(tagId),
      );
    },
    false,
    'searches/remove-tags',
  );
};

export const setSearchMode = (searchMode: 'crossDocumentSearch' | 'inDocumentSearch') => {
  useSearchStore.setState(
    (state) => {
      state.searchMode = searchMode;
    },
    false,
    'searches/set-search-mode',
  );
};
