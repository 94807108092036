import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserDataStore } from '../services/firebase/state/userDataStore';
import { NotFound } from '@/features/Common/Error/404';

export const SetDefaultDataRole = () => {
  const { isInitialized, dataRoleLookup } = useUserDataStore();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    if (!dataRoleLookup || Object.keys(dataRoleLookup).length === 0) {
      if (isInitialized) {
        setError(true);
      }
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');

    if (role) {
      const urlParamRole =
        dataRoleLookup[role] ||
        Object.keys(dataRoleLookup).find(
          (key) => dataRoleLookup[key].label.toLowerCase() === role.toLowerCase(),
        ) ||
        Object.keys(dataRoleLookup).find((key) => dataRoleLookup[key].roleName === role);

      if (urlParamRole) {
        navigate(`/${urlParamRole.urlParam || urlParamRole.roleName}` + search);
        return;
      }
    }

    navigate(`/${Object.keys(dataRoleLookup)[0]}` + search);
  }, [dataRoleLookup, navigate, isInitialized, search]);

  if (error) {
    return (
      <NotFound
        title={t('errors.dataRole404Title')}
        description={t('errors.dataRole404Description')}
      />
    );
  }

  return null;
};
