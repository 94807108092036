import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { z } from 'zod';
import { alertError } from '@/utils/alertError';

const companySchema = z.object({
  companyName: z.string(),
});

export type Company = z.infer<typeof companySchema>;

export async function getCompanyRequest(companyId: string) {
  try {
    const companyDocRef = doc(db, 'companies', companyId);
    const companyDocSnapshot = await getDoc(companyDocRef);

    if (!companyDocSnapshot.exists()) {
      throw new Error('Company not found');
    }

    const company = companySchema.parse(companyDocSnapshot.data());
    return company;
  } catch (error) {
    alertError(error, { function: 'getCompanyRequest' }, { companyId });
    throw error;
  }
}
