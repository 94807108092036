import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

export async function resetPassword(code: string, password: string, passwordConfirmation: string) {
  try {
    if (password === passwordConfirmation) {
      await verifyPasswordResetCode(auth, code);
      await confirmPasswordReset(auth, code, password);
    } else {
      throw new Error('Passwords do not match');
    }
  } catch (error) {
    alertError(error, { function: 'resetPassword' }, { code });
    throw error;
  }
}
