import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { BBSchema } from '../commonSchemas';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

export const keywordSchema = z
  .object({
    keyword: z.string(),
    page_number: z.number(),
    bbs: z.array(BBSchema).nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type Keyword = z.infer<typeof keywordSchema>;

const keywordHighlightsSchema = z
  .object({
    highlights: z.array(keywordSchema),
  })
  .transform(convertSnakeToCamelCaseObject);

export type KeywordHighlights = z.infer<typeof keywordHighlightsSchema>;

async function getKeywordHighlights(
  documentId: string,
  keywords?: string[],
  pageNumbers?: number[],
): Promise<KeywordHighlights> {
  if (!documentId || !keywords) {
    return { highlights: [] };
  }

  try {
    const token = await getAuthToken();
    const response = await axios.post(
      `${REST_API_URL}/database/document/${documentId}/keyword-highlights`,
      {
        keywords: keywords,
        page_numbers: pageNumbers ?? null,
      },
      standardAxiosOptions(token),
    );
    return keywordHighlightsSchema.parse(response.data);
  } catch (error) {
    alertError(error, { function: 'getKeywordHighlights' }, { documentId, keywords, pageNumbers });
    throw error;
  }
}

export const useKeywordHighlights = (
  documentId: string,
  keywords?: string[],
  pageNumbers?: number[],
) => {
  return useQuery({
    queryKey: ['keyword_highlights', documentId, keywords, pageNumbers],
    queryFn: () => getKeywordHighlights(documentId, keywords, pageNumbers),
    retry: false,
  });
};
