import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from './getCurrentUser';
import { db } from '../firebaseConfig';
import { z } from 'zod';
import { searchParamsSchema } from '@/services/api/commonSchemas';
import { alertError } from '@/utils/alertError';

const FETCH_SEARCHES_LIMIT = 25;

const recentSearchesSchema = z.object({
  recent: z.array(
    z.object({
      query: z.string(),
      searchId: z.string(),
    }),
  ),
});

export type RecentSearches = z.infer<typeof recentSearchesSchema>;

async function getRecentSearches(maxSearches: number): Promise<RecentSearches> {
  try {
    const user = getCurrentUser();

    if (!user) {
      throw new Error('No user found');
    }

    const searchesRef = collection(db, 'searches');
    const q = query(
      searchesRef,
      where('user_id', '==', user.uid),
      where('hidden', '!=', true),
      orderBy('created_at', 'desc'),
      limit(FETCH_SEARCHES_LIMIT),
    );
    const searchesSnapshot = await getDocs(q);

    if (searchesSnapshot.empty) {
      return recentSearchesSchema.parse({ recent: [] });
    }

    const queries = new Set<string>();
    const searches: RecentSearches = { recent: [] };

    const searchPromises = searchesSnapshot.docs.map(async (searchDoc) => {
      if (searches.recent.length >= maxSearches) return;

      const actionsRef = collection(searchDoc.ref, 'actions');
      const lastActionQuery = query(
        actionsRef,
        where('content_type', '==', 'search'),
        orderBy('submitted_at', 'desc'),
        limit(1),
      );
      const lastActionSnapshot = await getDocs(lastActionQuery);

      if (lastActionSnapshot.empty) {
        console.warn('No actions found for search:', searchDoc.id);
        return;
      }

      const action = lastActionSnapshot.docs[0].data();
      const params = searchParamsSchema.parse(action['content']);

      if (!queries.has(params.query)) {
        queries.add(params.query);
        return {
          query: params.query,
          searchId: searchDoc.data()['search_id'],
        };
      }
    });

    const searchResults = await Promise.all(searchPromises);
    searches.recent = searchResults
      .filter(
        (result): result is { query: string; searchId: string } =>
          result !== undefined && typeof result.searchId === 'string',
      )
      .slice(0, maxSearches);

    return recentSearchesSchema.parse({ recent: searches.recent });
  } catch (error) {
    alertError(
      error,
      { function: 'getRecentSearches', component: 'useRecentSearches' },
      { maxSearches },
    );
    return recentSearchesSchema.parse({ recent: [] });
  }
}

export const useRecentSearches = (maxSearches: number) => {
  return useQuery({
    queryKey: ['recent_searches', maxSearches],
    queryFn: () => getRecentSearches(maxSearches),
  });
};
