import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchBarDropdown } from './SearchBarDropdown';
import { SearchBarInput } from './SearchBarInput';
import { useSearchStore } from '../../state/searchStore';
import { useDataRole } from '../../state/hooks/useDataRole';
import { setQuery } from '../../state/actions/searchParams';
import { useDocumentSuggestions } from '@/services/api/actions/useDocumentSuggestions';
import { useRecentSearches } from '@/services/firebase/functions/getRecentSearches';

type Props = {
  onSearch: () => void;
  onOpenHistoryModal: () => void;
};

export const SearchBar: FC<Props> = ({ onSearch, onOpenHistoryModal }) => {
  const { t } = useTranslation();
  const query = useSearchStore((state) => state.currentParams.query);
  const [inFocus, setInFocus] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { dataRole, dataRoleUrlParam } = useDataRole();
  const searchMode = useSearchStore((state) => state.searchMode);
  const { data: recentSearches } = useRecentSearches(5);
  const { data: documentSuggestions } = useDocumentSuggestions(query, dataRole || undefined);
  const [dropdownIndex, setDropdownIndex] = useState<number | null>(null);
  const navigate = useNavigate();

  const showDropdown = useMemo(
    () =>
      inFocus &&
      searchMode === 'crossDocumentSearch' &&
      (query.trim().length === 0
        ? recentSearches && recentSearches.recent.length > 0
        : documentSuggestions && documentSuggestions.completions.length > 0),
    [inFocus, searchMode, query, recentSearches, documentSuggestions],
  );

  const onFocus = () => {
    setInFocus(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setInFocus(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (
      (!recentSearches?.recent.length && !documentSuggestions?.completions.length) ||
      query.length > 0
    ) {
      setDropdownIndex(null);
    }
  }, [recentSearches?.recent.length, documentSuggestions?.completions.length, query.length]);

  const onSearchClick = () => {
    if (dropdownIndex !== null && documentSuggestions?.completions.length) {
      const completion = documentSuggestions.completions[dropdownIndex];
      if (completion) {
        navigate(`/${dataRoleUrlParam}/document/${completion.document_id}`);
        return;
      }
    }

    if (dropdownIndex !== null && recentSearches?.recent.length) {
      const search = recentSearches.recent[dropdownIndex];
      if (search) {
        setQuery(search.query);
        navigate(`/${dataRoleUrlParam}/search/${search.searchId}`);
        return;
      }
    }
    onSearch();
    textareaRef.current?.blur();
  };

  const handleKeyUpDown = (direction: number) => {
    const maxIndex =
      (recentSearches?.recent.length || 0) + (documentSuggestions?.completions.length || 0) - 1;
    setDropdownIndex((prev) => {
      if (prev === null) {
        return direction === 1 ? maxIndex : 0;
      } else {
        const newIndex = prev - direction;
        if (newIndex < 0 || newIndex > maxIndex) {
          return null;
        } else {
          return newIndex;
        }
      }
    });
  };

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={`flex flex-col w-full px-5 py-4 rounded-t-[28px] text-qura-neutral-jet bg-white text-sm shadow-qura z-20 ${showDropdown ? '' : 'rounded-b-[28px]'}`}>
        <SearchBarInput
          onKeyUpDown={handleKeyUpDown}
          searchMode={searchMode}
          onFocus={onFocus}
          query={query}
          queryChanged={setQuery}
          onSearchClick={onSearchClick}
          placeholder={t('searchBar.placeholder')}
        />
      </div>
      {showDropdown && recentSearches && documentSuggestions && (
        <div className="absolute w-full z-10">
          <SearchBarDropdown
            selectedIndex={dropdownIndex}
            query={query}
            onOpenHistoryModal={onOpenHistoryModal}
            recentSearches={recentSearches}
            documentSuggestions={documentSuggestions}
          />
        </div>
      )}
    </div>
  );
};
