import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingCover } from '../../Common/Loading/LoadingCover';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import { useDataRole } from '@/features/Search/state/hooks/useDataRole';

const TIMEOUT_MS = 15000;

type AuthRedirectProps = {
  onLoggedIn?: string;
  onLoggedOut?: string;
  onEmailNotVerified?: string;
  requireDataRole?: boolean;
  children?: React.ReactNode;
};
export const AuthRedirect = ({
  children,
  onLoggedIn,
  onLoggedOut,
  onEmailNotVerified,
  requireDataRole = false,
}: AuthRedirectProps) => {
  const location = useLocation();
  const { isInitialized, data } = useUserDataStore();
  const [timedOut, setTimedOut] = useState(false);
  const { dataRole } = useDataRole();

  const authUser = data?.authUser;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!isInitialized) {
      timeoutId = setTimeout(() => {
        if (!isInitialized) {
          setTimedOut(true);
        }
      }, TIMEOUT_MS);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isInitialized]);

  if (isInitialized) {
    if (authUser && !authUser.emailVerified && onEmailNotVerified) {
      return <Navigate to={onEmailNotVerified} replace state={{ from: location }} />;
    }

    if ((!authUser || (requireDataRole && !dataRole)) && onLoggedOut) {
      return <Navigate to={onLoggedOut} replace state={{ from: location }} />;
    }

    if (authUser && onLoggedIn) {
      return <Navigate to={onLoggedIn} replace state={{ from: location }} />;
    }
  }

  if (timedOut) {
    return <Navigate to={'/error'} replace state={{ from: location }} />;
  }

  return <LoadingCover isLoading={!isInitialized}>{children}</LoadingCover>;
};
