import isEqual from 'lodash.isequal';
import { useSearchStore } from '../searchStore';

export const useFilterHasChanged = (searchId: string) => {
  const hasChanged = useSearchStore(
    (state) =>
      !isEqual(
        state.currentParams.filter,
        state.crossDocumentSearches[searchId]?.submittedParams.filter,
      ),
  );
  return hasChanged;
};
