import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

const pagePropertiesSchema = z
  .object({
    height: z.number(),
    width: z.number(),
    page_number: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type PageProperties = z.infer<typeof pagePropertiesSchema>;

export const documentPropertiesSchema = z
  .object({
    scale_factor: z.number().default(1.2),
    file_size: z.number(),
    page_count: z.number(),
    document_unit_id: z.string(),
    page_properties: z.array(pagePropertiesSchema),
    legal_id: z.string().nullable().default(null),
    title: z.string(),
    year: z.number().int().nullable().default(null),
    month: z.number().int().nullable().default(null),
    day: z.number().int().nullable().default(null),
    language: z.string().nullable().default(null),
    document_status: z.string().nullable().default(null),
    source_link: z.string().nullable().default(null),
    additional_info: z.record(z.string()).nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type DocumentProperties = z.infer<typeof documentPropertiesSchema>;

async function getDocumentProperties(
  documentId: string,
  documentUnitId?: string,
): Promise<DocumentProperties> {
  try {
    const token = await getAuthToken();
    const response = await axios.get(
      `${REST_API_URL}/database/document/${documentId}/properties?document_unit_id=${documentUnitId}`,
      standardAxiosOptions(token),
    );
    const res = documentPropertiesSchema.parse(response.data);
    if (res.pageCount !== res.pageProperties.length) {
      throw new Error('Page count does not match page properties length');
    }
    return res;
  } catch (error) {
    alertError(error, { function: 'getDocumentProperties' }, { documentId, documentUnitId });
    throw error;
  }
}

export const useDocumentProperties = (documentId: string, documentUnitId?: string) => {
  return useQuery({
    queryKey: ['document_properties', documentId],
    queryFn: () => getDocumentProperties(documentId, documentUnitId),
    retry: false,
  });
};
