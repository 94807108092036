import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Tag from '../Tag/Tag';
import { useDataRole } from '../../state/hooks/useDataRole';
import { BB } from '@/services/api/commonSchemas';
import { Icons } from '@/assets';
import { RelatedDocument } from '@/services/api/actions/useDocumentRelations';

export const TextHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(217, 255, 217, 1)',
  rects,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  rects: BB[];
}) => {
  return (
    <>
      {rects.map((rect, index) => (
        <div
          key={index}
          onClick={onClick}
          onMouseEnter={() => onHoverStateChange?.(true)}
          onMouseLeave={() => onHoverStateChange?.(false)}
          style={{
            cursor: 'default',
            userSelect: 'none',
            pointerEvents: 'none',
            position: 'absolute',
            zIndex: 100,
            mixBlendMode: 'multiply',
            background: color,
            top: rect.y0,
            left: rect.x0,
            width: rect.x1 - rect.x0,
            height: rect.y1 - rect.y0,
          }}
        />
      ))}
    </>
  );
};

export const KeywordHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(170, 255, 170, 1)',
  borderColor = 'rgba(120, 255, 120, 1)',
  rects,
  borderWidth = 1.2,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  borderColor?: string;
  rects: BB[];
  borderWidth?: number;
}) => {
  return (
    <>
      {rects.map((rect, index) => (
        <div
          key={index}
          onClick={onClick}
          onMouseEnter={() => onHoverStateChange?.(true)}
          onMouseLeave={() => onHoverStateChange?.(false)}
          style={{ position: 'absolute', cursor: 'pointer' }}>
          {/* Top border */}
          <div
            key={`${index}-top`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0,
              left: rect.x0,
              width: rect.x1 - rect.x0,
              height: borderWidth,
            }}
          />
          {/* Bottom border */}
          <div
            key={`${index}-bottom`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y1 - borderWidth,
              left: rect.x0,
              width: rect.x1 - rect.x0,
              height: borderWidth,
            }}
          />
          {/* Left border */}
          <div
            key={`${index}-left`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0 + borderWidth,
              left: rect.x0,
              width: borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
          {/* Right border */}
          <div
            key={`${index}-right`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0 + borderWidth,
              left: rect.x1 - borderWidth,
              width: borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
          {/* Highlight in the middle */}
          <div
            key={`${index}-middle`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 100,
              mixBlendMode: 'multiply',
              background: color,
              top: rect.y0 + borderWidth,
              left: rect.x0 + borderWidth,
              width: rect.x1 - rect.x0 - 2 * borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
        </div>
      ))}
    </>
  );
};

const ReferencePopup = ({
  coordinates,
  onMouseEnter,
  onMouseLeave,
  reference,
  redirectUrl,
  notFound,
}: {
  coordinates: { x: number; y: number };
  onMouseEnter: (rect?: BB) => void;
  onMouseLeave: () => void;
  reference?: RelatedDocument;
  redirectUrl?: string;
  notFound: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (reference?.remoteLegalId) {
      navigator.clipboard.writeText(reference.remoteLegalId);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleLegalIdClick = (e: React.MouseEvent) => {
    if (!reference || notFound) return;
    e.stopPropagation();
    window.open(redirectUrl, '_blank');
  };

  return (
    <div
      className="z-[1000] max-w-[300px] p-3 rounded-md border bg-popover text-popover-foreground shadow-md outline-none cursor-pointer pointer-events-auto"
      style={{
        position: 'absolute',
        top: coordinates.y,
        left: coordinates.x,
        transform: 'translate(-50%, -100%)',
      }}
      onClick={() => setExpanded(!expanded)}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={onMouseLeave}>
      {reference?.remoteLegalId && !notFound && (
        <div className="flex gap-2  mb-3">
          <div
            className="flex items-center justify-center h-6 w-7 rounded border bg-qura-neutral-ghost hover:border-transparent hover:bg-qura-neutral-mist"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}>
            <Icons.Info className="w-4 h-4" />
          </div>
          <Tag
            onClick={handleLegalIdClick}
            className="cursor-pointer flex overflow-hidden flex-shrink md bg-qura-neutral-ghost rounded px-2 border hover:border-transparent hover:bg-qura-neutral-mist h-6">
            <Icons.Document className="mr-2 mb-[1px] flex-shrink-0" />
            <p
              className="font-medium overflow-hidden text-ellipsis whitespace-nowrap flex-shrink"
              title={reference.remoteLegalId}>
              {reference.remoteLegalId}
            </p>
            <Icons.ArrowTiltedUpRight className="ml-2.5 flex-shrink-0 w-3.5 h-3.5 -rotate-90" />
          </Tag>
          <button
            onClick={handleCopy}
            className="flex justify-center w-6 h-6 items-center border border-gray-200 bg-qura-neutral-ghost rounded-md hover:bg-qura-neutral-mist">
            {copied ? (
              <Icons.Checked className="w-3 h-3 filter invert" />
            ) : (
              <Icons.Copy className="w-3.5 h-3.5" />
            )}
          </button>
        </div>
      )}
      {reference?.remoteTitle && !notFound && (
        <p className={'text-xs text-gray-500 ' + (expanded ? 'line-clamp-none' : 'line-clamp-3')}>
          {reference?.remoteTitle}
        </p>
      )}
      {notFound && (
        <p className={'text-xs text-gray-500 ' + (expanded ? 'line-clamp-none' : 'line-clamp-3')}>
          {t('searchDocumentPage.referenceNotFound')}
        </p>
      )}
    </div>
  );
};

export const LinkHighlight = ({
  focusLinksWith,
  onHoverStateChange,
  color = '#003DDA',
  hoverColor = '#6b8eff33',
  borderColor = '#6b8eff77',
  reference,
  zoomedBbs,
  id,
  setPopupValue,
  popupValue,
}: {
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  id: string;
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  reference: RelatedDocument;
  zoomedBbs?: BB[];
  setPopupValue: (value: string | null) => void;
  popupValue: string | null;
}) => {
  const { dataRoleUrlParam } = useDataRole();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get('searchId');
  const redirectUrl =
    `${window.location.origin}/${dataRoleUrlParam}/document/${reference.remoteDocumentId}` +
    (searchId ? `?searchId=${searchId}` : '');
  const notFound = useMemo(() => reference === undefined || !reference.isActive, [reference]);
  const [popupCoordinates, setPopupCoordinates] = useState<{ x: number; y: number } | null>(null);

  const showPopup = useMemo(() => {
    return popupValue === id;
  }, [id, popupValue]);

  const focus = useMemo(() => {
    return (
      focusLinksWith &&
      focusLinksWith.remoteDocumentId === reference.remoteDocumentId &&
      focusLinksWith.localPageNumber === reference.localPageNumber
    );
  }, [focusLinksWith, reference]);

  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (rect?: BB) => {
    if (rect) {
      setPopupCoordinates({ x: rect.x0 + (rect.x1 - rect.x0) / 2, y: rect.y0 - 10 });
    }
    setPopupValue(id);
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
    onHoverStateChange?.(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      onHoverStateChange?.(false);
      setPopupValue(id === popupValue ? null : popupValue);
    }, 350);
    setCloseTimeout(timeout);
  };

  useEffect(() => {
    if (popupValue !== id) {
      onHoverStateChange?.(false);
    }
    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, [popupValue, id, onHoverStateChange, closeTimeout]);

  return reference ? (
    <>
      {zoomedBbs?.map((rect, index) => (
        <div
          key={index}
          onClick={() => {
            if (!notFound) {
              window.open(redirectUrl, '_blank');
            }
          }}
          onMouseEnter={() => handleMouseEnter(rect)}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            position: 'absolute',
            zIndex: 100,
            mixBlendMode: showPopup || focus ? 'multiply' : 'screen',
            background: showPopup ? hoverColor : focus ? 'rgba(255, 255, 0, 1)' : color,
            top: rect.y0,
            left: rect.x0 - 2,
            width: rect.x1 - rect.x0 + 4,
            height: rect.y1 - rect.y0,
            border: showPopup ? '1px solid ' + borderColor : '',
          }}
        />
      ))}
      {showPopup && popupCoordinates && (
        <ReferencePopup
          reference={reference}
          coordinates={popupCoordinates}
          redirectUrl={redirectUrl}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          notFound={notFound}
        />
      )}
    </>
  ) : null;
};
