const changeToWebsocket = (url: string): string => {
  return url.replace('http', 'ws').replace('https', 'wss');
};

export const REST_API_URL = `${import.meta.env.VITE_RESOURCE_API_URL || window.location.origin}${import.meta.env.VITE_RESOURCE_API_PREFIX}`;
export const WS_API_URL = changeToWebsocket(
  `${import.meta.env.VITE_SEARCH_API_URL || window.location.origin}${import.meta.env.VITE_SEARCH_API_PREFIX}`,
);

export const MAX_COMPLETIONS_PER_SEARCH = 3;
