import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { z } from 'zod';
import { db } from '../../firebase/firebaseConfig';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';
import { queryClient } from '@/services/react-query/queryClient';

export type TagNode = {
  tagId: string;
  label: string;
  description: string | null;
  weight: number;
  subNodes: TagNode[];
};

const tagNodeSchema = z
  .object({
    tag_id: z.string(),
    label: z.string(),
    description: z.string().nullable().default(null),
    weight: z.number().int(),
    sub_nodes: z
      .lazy(() => tagNodeSchema.array())
      .nullable()
      .default([]),
  })
  .transform(convertSnakeToCamelCaseObject) as unknown as z.ZodType<TagNode>;

const availableFiltersSchema = z
  .object({
    tag_nodes: tagNodeSchema.array(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type AvailableFilters = z.infer<typeof availableFiltersSchema>;

async function getAvailableFilterTagsRequest(dataRole?: string): Promise<AvailableFilters> {
  if (!dataRole) {
    return { tagNodes: [] };
  }

  try {
    const docRef = doc(db, 'dataRoles', dataRole);
    const document = await getDoc(docRef);
    if (!document.exists()) {
      throw new Error('Data role document does not exist');
    }

    const data = document.data();
    return availableFiltersSchema.parse(data.tag_tree);
  } catch (error) {
    alertError(error, { function: 'getAvailableFilterTagsRequest' });
    throw error;
  }
}

export function getCurrentAvailableFilterTags(dataRole: string): AvailableFilters | undefined {
  const queryKey = ['filter_tags', dataRole];
  return queryClient.getQueryData<AvailableFilters>(queryKey);
}

export function transformTagSelection(
  transformTo: 'leaf-only' | 'parent-only' | 'all',
  selection: string[],
  availableTags: AvailableFilters,
): string[] {
  const { tagNodes } = availableTags;

  const newSelection = new Set<string>();

  const recursiveSelect = (tagNode: TagNode, parentSelected?: boolean): boolean => {
    const isLeaf = tagNode.subNodes.length === 0;

    const selected = selection.includes(tagNode.tagId);

    if (isLeaf) {
      if (transformTo == 'leaf-only') {
        if (parentSelected || selected) {
          newSelection.add(tagNode.tagId);
        }
      }

      return selected;
    }

    const subNodeSelected = tagNode.subNodes.map((sn) =>
      recursiveSelect(sn, selected || parentSelected),
    );
    const shouldBeSelected = subNodeSelected.every((s) => s) || selected;

    if (!shouldBeSelected && transformTo === 'parent-only') {
      tagNode.subNodes.forEach((sn, i) => {
        if (subNodeSelected[i]) {
          newSelection.add(sn.tagId);
        }
      });
    }

    if (transformTo === 'all' && shouldBeSelected) {
      newSelection.add(tagNode.tagId);
    }

    return shouldBeSelected;
  };

  if (transformTo === 'parent-only') {
    tagNodes.forEach((tn) => {
      if (recursiveSelect(tn)) {
        newSelection.add(tn.tagId);
      }
    });
  } else {
    tagNodes.forEach((tn) => recursiveSelect(tn));
  }

  return [...newSelection].sort();
}

export const useAvailableFilterTags = (dataRole?: string) => {
  return useQuery({
    queryKey: ['filter_tags', dataRole],
    queryFn: () => getAvailableFilterTagsRequest(dataRole),
    enabled: dataRole !== null,
  });
};
