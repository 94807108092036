import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { MAX_COMPLETIONS_PER_SEARCH, REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { alertError } from '@/utils/alertError';

const documentSuggestionsSchema = z.object({
  completions: z.array(
    z.object({
      legal_id: z.string(),
      title: z.string(),
      document_id: z.string(),
    }),
  ),
});

export type DocumentSuggestions = z.infer<typeof documentSuggestionsSchema>;

const getDocumentSuggestions = async (
  prefix: string,
  dataRole?: string,
): Promise<DocumentSuggestions> => {
  if (prefix.trim().length === 0 || !dataRole) {
    return { completions: [] };
  }

  try {
    const token = await getAuthToken();
    const response = await axios.post<{
      completions: { legal_id: string; title: string; document_id: string }[];
    }>(
      `${REST_API_URL}/database/completion/legal`,
      {
        prefix,
        limit: MAX_COMPLETIONS_PER_SEARCH,
        data_role: dataRole,
      },
      {
        responseType: 'json',
        ...standardAxiosOptions(token),
      },
    );

    return documentSuggestionsSchema.parse(response.data);
  } catch (error) {
    alertError(error, { function: 'getDocumentSuggestions' }, { prefix, dataRole });
    throw error;
  }
};

export const useDocumentSuggestions = (prefix: string, dataRole?: string) => {
  return useQuery({
    queryKey: ['document_suggestions', prefix, dataRole],
    queryFn: () => getDocumentSuggestions(prefix, dataRole),
    placeholderData: keepPreviousData,
  });
};
