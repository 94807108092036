import { FC, ReactNode } from 'react';

const Tag: FC<{
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  className?: string;
}> = ({ children, onClick, onMouseEnter, className }) => {
  const defaultBg = className?.match(/ bg-/) ? '' : 'bg-qura-neutral-ghost';

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={`${defaultBg} flex items-center rounded px-2 py-1 text-xs ${className}`}>
      {children}
    </div>
  );
};

export default Tag;
