import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import posthog from 'posthog-js';
import { useSearchParams } from 'react-router-dom';
import Tag from '../../components/Tag/Tag';
import { useDataRole } from '../../state/hooks/useDataRole';
import { useDocumentName } from '@/services/api/actions/useDocumentName';
import { Icons } from '@/assets';
import {
  RelatedDocument,
  Tags,
  useDocumentRelationTags,
  useInfiniteDocumentRelations,
} from '@/services/api/actions/useDocumentRelations';
import { BB } from '@/services/api/commonSchemas';
import { POSTHOG_EVENT } from '@/services/posthog/events';

const EU_TAGS = [
  'Secondary Law',
  'Case Law',
  'Primary Law',
  'ESMA',
  'EBA',
  'Rättspraxis',
  'Författning',
  'Förarbete',
];

const SWEDEN_TAGS = [
  'Rättspraxis',
  'Förarbete',
  'Författning',
  'EU Sekundärrätt',
  'EU Rättspraxis',
  'EBA',
  'ESMA',
];

const TAGS = {
  'eu-case-law-1': EU_TAGS,
  'eu-legislation-1': EU_TAGS,
  'eu-special-1': EU_TAGS,
  'sweden-basics-1': SWEDEN_TAGS,
  'sweden-special-1': SWEDEN_TAGS,
};

const PAGE_SIZE = 20;

const RelatedDocumentEntry = ({
  documentId,
  legalId,
  locations,
  setFilePosition,
  setFocusLinksWith,
}: {
  documentId: string;
  documentUnitId?: string;
  legalId?: string;
  title: string;
  locations: { pageNumber: number; bbs: BB[] }[];
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
}) => {
  const { dataRoleUrlParam } = useDataRole();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get('searchId');
  const { data: documentName } = useDocumentName(documentId);
  const { t } = useTranslation();

  const handleLegalIdClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    posthog.capture(POSTHOG_EVENT.OPEN_RELATED_DOCUMENT, {
      documentId,
    });
    const redirectUrl =
      `${window.location.origin}/${dataRoleUrlParam}/document/${documentId}` +
      (searchId ? `?searchId=${searchId}` : '');
    window.open(redirectUrl, '_blank');
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsExpanded(!isExpanded);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li
      key={documentId}
      className="flex flex-col justify-center items-end p-3 w-full bg-white shadow-lg rounded-md cursor-pointer"
      onClick={handleClick}>
      <div className="flex flex-col items-start w-full gap-2">
        <div className="flex w-full">
          <div
            className="flex items-center justify-center h-6 w-7 mr-1 rounded border bg-qura-neutral-ghost hover:border-transparent hover:bg-qura-neutral-mist"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}>
            <Icons.Info className="w-4 h-4" />
          </div>
          <Tag
            onClick={(e) => {
              e.stopPropagation();
              handleLegalIdClick(e);
            }}
            className={`flex items-center justify-center cursor-pointer bg-qura-neutral-ghost rounded px-2 border hover:border-transparent hover:bg-qura-neutral-mist ${
              isExpanded ? 'h-auto' : 'h-6 overflow-hidden'
            }`}>
            <Icons.Document className="flex-shrink-0 mr-2 mb-[1px]" />
            <p
              className={`font-medium transition-all duration-300 ease-in-out ${
                isExpanded
                  ? 'whitespace-normal break-words'
                  : 'text-ellipsis whitespace-nowrap overflow-hidden'
              }`}
              title={legalId ?? '-'}>
              {legalId ?? '-'}
            </p>
            <Icons.ArrowTiltedUpRight className="flex-shrink-0 w-3.5 h-3.5 ml-2.5 -rotate-90" />
          </Tag>
        </div>
        <p className={`text-xs text-gray-500 ${isExpanded ? 'line-clamp-none' : 'line-clamp-3'}`}>
          {documentName?.title}
        </p>
        <div
          className={`flex gap-1 flex-wrap justify-end self-stretch ${isExpanded ? '' : 'max-h-5 overflow-hidden'}`}>
          {locations.map((location) => (
            <div
              key={location.pageNumber}
              onClick={(e) => {
                e.stopPropagation();
                setFilePosition({
                  pageNumber: location.pageNumber,
                  offset:
                    location.bbs.length === 0
                      ? 0
                      : Math.max(
                          location.bbs.reduce((acc, bb) => (acc > bb.y0 ? bb.y0 : acc), 10e6) - 150,
                          0,
                        ),
                });
                posthog.capture(POSTHOG_EVENT.GO_TO_RELATED_DOCUMENT, {
                  documentId,
                  pageNumber: location.pageNumber,
                });
                setFocusLinksWith({
                  remoteDocumentId: documentId,
                  localPageNumber: location.pageNumber,
                });
              }}
              className="text-xs text-black hover:underline border leading-none bg-qura-neutral-ghost px-[4px] py-[2px] rounded hover:bg-qura-neutral-mist">
              {t('searchResult.pageNumberShort')}
              {location.pageNumber + 1}
            </div>
          ))}
        </div>
      </div>
    </li>
  );
};

const TagSlider = ({
  tagIds,
  selectedTagId,
  setSelectedTagId,
  counts,
}: {
  tagIds: string[];
  selectedTagId: string;
  setSelectedTagId: (tagId: string) => void;
  counts?: { [key: string]: number };
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    checkScrollPosition();
    window.addEventListener('resize', checkScrollPosition);
    return () => window.removeEventListener('resize', checkScrollPosition);
  }, [tagIds]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowRightArrow(scrollWidth > clientWidth);
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth / 2;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
      setTimeout(checkScrollPosition, 300);
    }
  };

  return (
    <div className="relative flex items-center w-full">
      {showLeftArrow && (
        <>
          <div className="absolute left-0 z-10 w-20 h-full bg-gradient-to-r from-white pointer-events-none"></div>
          <button className="absolute left-0 z-10 p-2 rounded-full " onClick={() => scroll('left')}>
            <Icons.Arrow className="w-3.5 h-3.5 rotate-90" />
          </button>
        </>
      )}
      <div
        ref={scrollContainerRef}
        className={`flex overflow-x-auto whitespace-nowrap scrollbar-hide px-6`}
        onScroll={checkScrollPosition}>
        {tagIds.map((tagId) => (
          <button
            key={tagId}
            className={`inline-flex items-center justify-center rounded-full px-3 py-1 mr-2 text-sm transition-colors ${
              selectedTagId === tagId
                ? 'bg-qura-neutral-jet text-qura-neutral-mist text-xs'
                : 'bg-qura-neutral-ghost text-qura-neutral-balanced hover:bg-qura-neutral-mist text-xs'
            }`}
            onClick={() => setSelectedTagId(tagId)}>
            {tagId}
            {counts && counts[tagId] > 0 && (
              <span className="ml-1 px-1.5 bg-qura-neutral-balanced text-qura-neutral-mist text-xs rounded-full">
                {counts[tagId]}
              </span>
            )}
          </button>
        ))}
      </div>
      {showRightArrow && (
        <>
          <div className="absolute right-0 z-10 w-20 h-full bg-gradient-to-l from-white pointer-events-none"></div>
          <button
            className="absolute right-0 z-10 p-2 rounded-full "
            onClick={() => scroll('right')}>
            <Icons.Arrow className="w-3.5 h-3.5 -rotate-90" />
          </button>
        </>
      )}
    </div>
  );
};

const RelatedDocumentList = ({
  documentId,
  relationType,
  selectedTagId,
  setFilePosition,
  translationKey,
  setFocusLinksWith,
}: {
  documentId: string;
  relationType: 'outgoing_mention' | 'incoming_mention' | 'related';
  selectedTagId: string;
  translationKey: string;
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const {
    data: documents,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteDocumentRelations(
    documentId,
    relationType,
    selectedTagId ? [selectedTagId as Tags] : undefined,
    PAGE_SIZE,
  );

  const allUniqueDocuments = useMemo(() => {
    const uniqueDocs = new Map<
      string,
      { related: RelatedDocument; locations: { pageNumber: number; bbs: BB[] }[] }
    >();
    documents?.pages.forEach((page) =>
      page.refs.forEach((doc) => {
        if (!uniqueDocs.has(doc.remoteDocumentId)) {
          uniqueDocs.set(doc.remoteDocumentId, { related: doc, locations: [] });
        }
        const entry = uniqueDocs.get(doc.remoteDocumentId);
        if (entry && doc.localPageNumber !== null && doc.localBbs) {
          const locationWithSamePageNumber = entry.locations.find(
            (location) => location.pageNumber === doc.localPageNumber,
          );
          if (locationWithSamePageNumber) {
            locationWithSamePageNumber.bbs.push(...doc.localBbs);
          } else {
            entry.locations.push({ pageNumber: doc.localPageNumber, bbs: doc.localBbs });
          }
        }
      }),
    );
    return Array.from(uniqueDocs.values()).map((entry) => ({
      ...entry,
      locations: entry.locations.sort((a, b) => a.pageNumber - b.pageNumber),
    }));
  }, [documents]);
  const totalCount = useMemo(() => documents?.pages[0]?.totalCount ?? 0, [documents]);

  const toggleExpand = () => setIsExpanded(totalCount > 0 ? !isExpanded : false);

  return (
    <div className="flex flex-col bg-white">
      <div
        className="sticky top-0 border-b border-gray-200 text-sm text-center py-3 cursor-pointer bg-white z-10"
        onClick={toggleExpand}>
        <p className="flex items-center justify-center">
          {t(translationKey)}
          <>
            <span className="ml-2 px-2 py-1 bg-qura-neutral-ghost text-qura-neutral-balanced rounded-full text-xs">
              {totalCount}
            </span>
            {totalCount > 0 && (
              <Icons.Arrow
                className={`ml-2 transition-transform ${isExpanded ? '' : 'rotate-180'}`}
              />
            )}
          </>
        </p>
      </div>
      {isExpanded && totalCount > 0 && (
        <div className="py-3 h-full flex flex-col items-center px-1">
          <ul className="space-y-3  h-full w-full">
            {allUniqueDocuments.map((doc, index) => (
              <RelatedDocumentEntry
                key={index}
                documentId={doc.related.remoteDocumentId}
                legalId={doc.related.remoteLegalId ?? undefined}
                title={doc.related.remoteTitle}
                locations={doc.locations}
                setFilePosition={setFilePosition}
                setFocusLinksWith={setFocusLinksWith}
              />
            ))}
          </ul>
          {hasNextPage && (
            <button
              className="w-full text-center py-2 mt-4 bg-qura-neutral-mist text-qura-neutral-balanced hover:bg-qura-neutral-ghost transition-colors rounded-md text-sm font-medium"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}>
              {isFetchingNextPage ? (
                <span className="flex items-center justify-center">{t('common.loading')}</span>
              ) : (
                t('common.loadMore')
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const CitedDocuments = ({
  documentId,
  selectedTagId,
  setFilePosition,
  setFocusLinksWith,
}: {
  documentId: string;
  selectedTagId: string;
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
}) => {
  return (
    <RelatedDocumentList
      relationType="outgoing_mention"
      translationKey="searchDocumentPage.citedDocuments"
      documentId={documentId}
      selectedTagId={selectedTagId}
      setFilePosition={setFilePosition}
      setFocusLinksWith={setFocusLinksWith}
    />
  );
};

const CitedByDocuments = ({
  documentId,
  selectedTagId,
  setFilePosition,
  setFocusLinksWith,
}: {
  documentId: string;
  selectedTagId: string;
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
}) => (
  <RelatedDocumentList
    relationType="incoming_mention"
    translationKey="searchDocumentPage.citedByDocuments"
    documentId={documentId}
    selectedTagId={selectedTagId}
    setFilePosition={setFilePosition}
    setFocusLinksWith={setFocusLinksWith}
  />
);

const RelatedDocuments = ({
  documentId,
  selectedTagId,
  setFilePosition,
  setFocusLinksWith,
}: {
  documentId: string;
  selectedTagId: string;
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
}) => (
  <RelatedDocumentList
    relationType="related"
    translationKey="searchDocumentPage.relatedDocuments"
    documentId={documentId}
    selectedTagId={selectedTagId}
    setFilePosition={setFilePosition}
    setFocusLinksWith={setFocusLinksWith}
  />
);

type RelatedDocsProps = {
  documentId: string;
  documentUnitId: string;
  dataRole: string;
  setFilePosition: (position: { pageNumber: number; offset: number }) => void;
  setFocusLinksWith: (
    focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null,
  ) => void;
};

export const DocumentRelationsColumn = ({
  documentId,
  documentUnitId,
  setFilePosition,
  setFocusLinksWith,
}: RelatedDocsProps) => {
  const { t } = useTranslation();
  const tags = TAGS[documentUnitId as keyof typeof TAGS];
  const availableTagMask = useDocumentRelationTags(documentId, tags as Tags[]);
  const availableTags = useMemo(
    () => tags.filter((_tag, index) => availableTagMask[index].data),
    [tags, availableTagMask],
  );

  const [selectedTagId, setSelectedTagId] = useState<string>(() => {
    const keys = availableTags.map((tag) => tag);
    return keys.length > 0 ? keys[0] : '';
  });

  useEffect(() => {
    if (!selectedTagId) {
      setSelectedTagId(availableTags[0]);
    }
  }, [availableTags, selectedTagId, setSelectedTagId]);

  return (
    <div className="flex flex-col pr-4 h-full pb-4">
      <div className="flex flex-col w-[300px] bg-white shadow-qura rounded-md pb-4 overflow-hidden">
        <div className="border-b border-gray-200 text-sm text-center py-5 h-[100px]">
          <p>{t('relatedDocuments.title')}</p>
          <div className="h-3" />
          <TagSlider
            tagIds={availableTags}
            selectedTagId={selectedTagId}
            setSelectedTagId={setSelectedTagId}
          />
        </div>
        <div className="flex-1 overflow-y-auto relative">
          <div className="px-6 p-4 gap-4 flex flex-col">
            <CitedDocuments
              documentId={documentId}
              selectedTagId={selectedTagId}
              setFilePosition={setFilePosition}
              setFocusLinksWith={setFocusLinksWith}
            />
            <CitedByDocuments
              documentId={documentId}
              selectedTagId={selectedTagId}
              setFilePosition={setFilePosition}
              setFocusLinksWith={setFocusLinksWith}
            />
            <RelatedDocuments
              documentId={documentId}
              selectedTagId={selectedTagId}
              setFilePosition={setFilePosition}
              setFocusLinksWith={setFocusLinksWith}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
