import { useMemo } from 'react';
import { useSearchStore } from '../../state/searchStore';
import {
  getCurrentAvailableFilterTags,
  transformTagSelection,
} from '@/services/api/actions/useAvailableFilterTags';
import { SearchParams } from '@/services/api/commonSchemas';

export const FilterBadge = ({ searchId, dataRole }: { searchId: string; dataRole: string }) => {
  const submittedFilter = useSearchStore(
    (state) =>
      state.crossDocumentSearches[searchId]?.submittedParams.filter as
        | SearchParams['filter']
        | undefined,
  );

  const filterCount = useMemo(() => {
    let count = 0;

    if (!submittedFilter) return count;
    if (submittedFilter.afterDate || submittedFilter.beforeDate) count++;

    const availableTags = getCurrentAvailableFilterTags(dataRole);
    if (!availableTags) return count;
    const transformedFilter = transformTagSelection(
      'parent-only',
      submittedFilter.tagIds,
      availableTags,
    );
    count += transformedFilter.length;

    return count;
  }, [submittedFilter, dataRole]);

  if (filterCount === 0) return null;

  return (
    <div className="absolute right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-neutral-jet text-white font-bold">
      {filterCount}
    </div>
  );
};
