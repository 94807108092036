import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { searchParamsSchema, webSocketOutputSchema } from '@/services/api/commonSchemas';
import devlog from '@/utils/devlog';
import { alertError } from '@/utils/alertError';
import {
  getCurrentAvailableFilterTags,
  transformTagSelection,
} from '@/services/api/actions/useAvailableFilterTags';

export async function getOldSearch(searchId: string) {
  try {
    const docRef = doc(db, 'searches', searchId);
    const document = await getDoc(docRef).catch((e) => {
      throw new Error('Unable to fetch search document (getOldSearch): ' + String(e));
    });
    devlog('Got search');
    if (document == undefined) {
      devlog('SEARCH IS UNDEFINED');
    }
    const latestResult = document.get('latest_result');
    if (latestResult == undefined) {
      devlog('NO LATEST RESULT');
    }
    if (!(latestResult instanceof DocumentReference)) {
      devlog('latest result is of invalid type', typeof latestResult);
    }

    const lastOutputSnapshot = await getDoc(latestResult).catch((e) => {
      throw new Error('Unable to fetch latest result (getOldSearch): ' + String(e));
    });

    devlog('Got last output');
    const output = webSocketOutputSchema.parse(lastOutputSnapshot.data());

    const actionRef = collection(docRef, 'actions');
    const lastAction = query(
      actionRef,
      where('content_type', '==', 'search'),
      orderBy('submitted_at', 'desc'),
      limit(1),
    );
    const lastActionSnapshot = await getDocs(lastAction).catch((e) => {
      throw new Error('Unable to fetch last action (getOldSearch): ' + String(e));
    });
    const action = lastActionSnapshot.docs[0].data();
    const submittedParams = searchParamsSchema.parse(action['content']);

    if (output.contentType !== 'result') {
      throw new Error('No actions found for search (getOldSearch)');
    }

    const availableFilterTags = submittedParams.dataRole
      ? getCurrentAvailableFilterTags(submittedParams.dataRole)
      : undefined;

    return {
      result: output.content,
      submittedParams: {
        ...submittedParams,
        filter: {
          ...submittedParams.filter,
          tagIds: availableFilterTags
            ? transformTagSelection('leaf-only', submittedParams.filter.tagIds, availableFilterTags)
            : [],
        },
      },
    };
  } catch (error) {
    alertError(
      error,
      {
        function: 'getOldSearch',
        message: 'An error occurred while fetching the old search.',
      },
      { searchId },
    );
    throw error;
  }
}
