import { Survey } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

export const usePostHogSurvey = () => {
  const posthog = usePostHog();
  const [survey, setSurvey] = useState<Survey | null>(null);

  useEffect(() => {
    posthog.getActiveMatchingSurveys((surveys) => {
      const firstSurvey = surveys.filter((survey) => survey.type === 'api')[0];
      const seenSurvey = firstSurvey && localStorage.getItem('seenSurvey_' + firstSurvey.id);
      if (firstSurvey && !seenSurvey) {
        setSurvey(firstSurvey);
        posthog.capture('survey shown', {
          $survey_id: firstSurvey.id,
          $survey_name: firstSurvey.name,
        });
      }
    });
  }, [posthog]);

  const submit = (response: number) => {
    if (!survey) return;
    posthog.capture('survey sent', {
      $survey_id: survey.id,
      $survey_name: survey.name,
      $survey_response: response,
    });
    localStorage.setItem('seenSurvey_' + survey.id, 'true');
    setSurvey(null);
  };

  const dismiss = () => {
    if (!survey) return;
    localStorage.setItem('seenSurvey_' + survey.id, 'true');
    setSurvey(null);
  };

  return { survey, submit, dismiss };
};
