import 'react-pdf/dist/Page/TextLayer.css';

import { Document, Page } from 'react-pdf';
import { VirtualItem, Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import { useEffect, useMemo, useRef } from 'react';
import { PDFPageLoading } from './LoadingPage';
import { Overlays } from './Overlay';
import { alertError } from '@/utils/alertError';
import { useAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { getDocumentUrl } from '@/services/api/utils';
import { FileModifications, PageModifications } from '@/services/api/commonSchemas';
import { DocumentProperties, PageProperties } from '@/services/api/actions/useDocumentProperties';
import { useDocumentRelations } from '@/services/api/actions/useDocumentRelations';
import { useKeywordHighlights } from '@/services/api/actions/useKeywords';

export type TestViewerPosition = {
  pageNumber: number;
  offset: number;
};

export const PageItem = ({
  virtualItem,
  documentId,
  pageProps,
  focusLinksWith,
  zoom,
  pageIndex,
  pageModifications,
  virtualizer,
  keywords,
}: {
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  virtualItem: Pick<VirtualItem<Element>, 'key' | 'index' | 'start'>;
  documentId: string;
  pageProps: PageProperties;
  zoom: number;
  pageIndex: number;
  pageModifications?: PageModifications;
  virtualizer: Pick<Virtualizer<HTMLDivElement, Element>, 'measureElement'>;
  keywords?: string[];
}) => {
  const { data: references } = useDocumentRelations(
    documentId,
    'outgoing_mention',
    undefined,
    pageIndex,
    100,
    0,
  );
  const { data: keywordHighlights } = useKeywordHighlights(documentId, keywords, [pageIndex]);
  return (
    <div
      key={virtualItem.key}
      ref={virtualizer.measureElement}
      data-index={virtualItem.index}
      className={`${
        pageIndex % 2 ? 'ListItemOdd' : 'ListItemEven'
      } top-0 left-0 right-0 mr-auto ml-auto absolute`}
      style={{
        width: pageProps.width * zoom,
        height: pageProps.height * zoom,
        transform: `translateY(${virtualItem.start}px)`,
      }}>
      {(pageModifications || references) && (
        <Overlays
          focusLinksWith={focusLinksWith}
          pageModifications={pageModifications}
          references={references?.refs ?? []}
          keywords={keywordHighlights?.highlights ?? []}
          zoom={zoom}
        />
      )}
      <Page
        pageIndex={virtualItem.index}
        width={pageProps.width}
        scale={zoom}
        loading={<PDFPageLoading width={pageProps.width} height={pageProps.height} />}
        error={<PDFPageLoading width={pageProps.width} height={pageProps.height} />}
        renderAnnotationLayer={false}
      />
    </div>
  );
};

const VirtualizerList = ({
  documentId,
  fileModifications,
  filePosition,
  focusLinksWith,
  onFilePositionChange,
  documentProps,
  zoom,
  keywords,
}: {
  documentId: string;
  fileModifications?: FileModifications;
  filePosition: TestViewerPosition;
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  onFilePositionChange: (position: TestViewerPosition) => void;
  documentProps: DocumentProperties;
  zoom: number;
  keywords?: string[];
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const pageProps = documentProps?.pageProperties;
  const virtualizer = useVirtualizer({
    count: documentProps?.pageCount,
    getScrollElement: () => ref.current,
    estimateSize: (i: number) => (pageProps[i]?.height || 0) * zoom,
    overscan: 10,
    gap: 10,
  });

  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    if (!virtualizer.range) return;
    const middleIndex = Math.floor((virtualizer.range.startIndex + virtualizer.range.endIndex) / 2);
    onFilePositionChange({
      pageNumber: middleIndex,
      offset: 0,
    });
  }, [onFilePositionChange, virtualizer.range]);

  useEffect(() => {
    const offset = virtualizer.getOffsetForIndex(filePosition.pageNumber, 'start');
    if (offset) virtualizer.scrollToOffset(offset[0] + filePosition.offset);
  }, [filePosition, virtualizer]);

  return (
    <div
      ref={ref}
      className="h-full w-full overflow-y-auto overflow-x-auto flex justify-center [contain:strict] bg-qura-neutral-ghost rounded-b-xl">
      <div style={{ height: virtualizer.getTotalSize() }} className={`relative w-full`}>
        {items.map((virtualItem) => (
          <PageItem
            key={virtualItem.key}
            virtualItem={virtualItem}
            pageIndex={virtualItem.index}
            documentId={documentId}
            pageProps={pageProps[virtualItem.index]}
            focusLinksWith={focusLinksWith}
            zoom={zoom}
            keywords={keywords}
            virtualizer={virtualizer}
            pageModifications={fileModifications?.pageOverrides?.find(
              (pageOverride) => pageOverride.pageNumber === virtualItem.index,
            )}
          />
        ))}
      </div>
    </div>
  );
};

const TextViewer = ({
  documentId,
  documentUnitId,
  fileModifications,
  documentProperties,
  zoom,
  onLoadSuccess,
  filePosition,
  onFilePositionChange,
  focusLinksWith,
  keywords,
}: {
  documentId: string;
  documentUnitId: string;
  fileModifications?: FileModifications;
  documentProperties: DocumentProperties;
  zoom: number;
  onLoadSuccess: () => void;
  filePosition: TestViewerPosition;
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  onFilePositionChange: (position: TestViewerPosition) => void;
  keywords?: string[];
}) => {
  const { data: token } = useAuthToken();

  const file = useMemo(
    () => ({ url: getDocumentUrl(documentId, documentUnitId) }),
    [documentId, documentUnitId],
  );

  const options = useMemo(() => ({ httpHeaders: { Authorization: `Bearer ${token}` } }), [token]);

  const onError = (err: unknown) => {
    alertError(
      err,
      { component: 'Virtualizer' },
      { documentId, documentUnitId, fileModifications },
    );
  };

  return (
    <>
      <Document
        file={file}
        options={options}
        onLoadSuccess={onLoadSuccess}
        onError={onError}
        onLoadError={onError}
        loading={
          <PDFPageLoading
            width={documentProperties?.pageProperties[0].width ?? 595}
            height={documentProperties?.pageProperties[0].height ?? 842}
          />
        }>
        <VirtualizerList
          documentId={documentId}
          fileModifications={fileModifications}
          documentProps={documentProperties}
          zoom={zoom}
          filePosition={filePosition}
          focusLinksWith={focusLinksWith}
          keywords={keywords}
          onFilePositionChange={onFilePositionChange}
        />
      </Document>
    </>
  );
};

export default TextViewer;
