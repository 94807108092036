/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSearchStore } from '../searchStore';
import { ResultDocument } from '@/services/api/commonSchemas';

const UPDATE_RESULTS_INTERVAL = 2000;
const FADE_OUT_BEFORE = 450;

export const useDebounceResults = (searchId: string) => {
  const resultDocumentsRef = useRef([] as ResultDocument[]);
  resultDocumentsRef.current = useSearchStore((state) =>
    searchId in state.crossDocumentSearches
      ? (state.crossDocumentSearches[searchId].results?.documents ?? [])
      : [],
  );
  const resultQueryRef = useRef<string | null>(null);
  resultQueryRef.current = useSearchStore((state) =>
    searchId in state.crossDocumentSearches
      ? (state.crossDocumentSearches[searchId].submittedParams.query ?? null)
      : null,
  );
  const gotResults = resultDocumentsRef.current.length > 0;
  const waitingForResults = useSearchStore((state) => !!state.crossDocumentSearches[searchId]?.ws);
  const [resultsToShow, setResultsToShow] = useState<ResultDocument[]>([]);
  const [noticeWillBeRemoved, setNoticeWillBeRemoved] = useState(false);

  const updateResults = () => {
    setResultsToShow(resultDocumentsRef.current);
    setNoticeWillBeRemoved(false);
  };

  const transitionToNewResults = () => {
    setNoticeWillBeRemoved(true);
    if (FADE_OUT_BEFORE > 0 && FADE_OUT_BEFORE < UPDATE_RESULTS_INTERVAL) {
      setTimeout(updateResults, FADE_OUT_BEFORE);
    } else {
      updateResults();
    }
  };

  useEffect(() => {
    if (waitingForResults) {
      setResultsToShow([]);
    }
  }, [waitingForResults]);

  useEffect(() => {
    if (waitingForResults && gotResults) {
      transitionToNewResults();
      const updateInterval = setInterval(transitionToNewResults, UPDATE_RESULTS_INTERVAL);
      return () => clearInterval(updateInterval);
    }

    if (!waitingForResults) {
      transitionToNewResults();
    }
  }, [waitingForResults, gotResults, searchId]);

  return {
    resultsFetched: resultDocumentsRef.current,
    resultsToShow,
    noticeWillBeRemoved,
    waitingForResults,
  };
};
