import { getAuth } from 'firebase/auth';
import { doc, getDoc, increment, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export const incrementUserSearchCount = async () => {
  const authUser = getAuth().currentUser;
  const userId = authUser?.uid;
  if (!userId) throw new Error('No user id found');
  const usersEditableRef = doc(db, 'usersEditable', userId);
  await updateDoc(usersEditableRef, { searchCount: increment(1) });
  const newCount = (await getDoc(usersEditableRef)).data()?.searchCount;
  return newCount;
};
