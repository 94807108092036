/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { ExamplePrompts } from './ExamplePrompts';
import { OnboardingModal } from './OnboardingModal';
import { DataRoleToggle } from '../../components/DataRoleToggle/DataRoleToggle';
import Feedback from '../../../Feedback/components/Feedback';
import { HelpButton } from '../../components/HelpButton/HelpButton';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import UserDropDown from '../../components/UserDropDown/UserDropDown';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { setQuery, setSearchMode } from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useDataRole } from '../../state/hooks/useDataRole';
import { useSearchStore } from '../../state/searchStore';
import SearchHistoryModal from '@/features/Search/components/SearchHistoryModal/SearchHistoryModal.tsx';
import { Icons } from '@/assets';
import { setUserSettings } from '@/services/firebase/functions/setUserSettings';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import { alertError } from '@/utils/alertError';
import { LoadingCover } from '@/features/Common/Loading/LoadingCover';
import { incrementUserSearchCount } from '@/services/firebase/functions/setUserSearchCount';

export const SearchPromptPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dataRole, dataRoleUrlParam } = useDataRole();
  const [isSearchHistoryModalOpened, setSearchHistoryModalOpened] = useState(false);
  const isInitialized = useUserDataStore((s) => s.isInitialized);
  const hasSeenQuickGuide = useUserDataStore((s) => s.data?.storedUser.hasSeenQuickGuide);
  const showQuickGuide = !hasSeenQuickGuide && hasSeenQuickGuide !== undefined && isInitialized;
  const isCreatingSearch = useSearchStore((s) => s.isCreatingSearch);

  // Initialize page
  useEffect(() => {
    // Set search mode
    setSearchMode('crossDocumentSearch');

    // If query is in params, run a search
    const queryFromParams = new URLSearchParams(window.location.search).get('query');
    if (queryFromParams) {
      setQuery(queryFromParams);
      onSearch();
    }
  }, []);

  // Set has seen quick guide
  const setHasSeenQuickGuid = () => {
    useUserDataStore.setState(
      (state) => {
        if (state.data) {
          state.data.storedUser.hasSeenQuickGuide = true;
        }
      },
      false,
      'users/update-settings',
    );

    setUserSettings({ hasSeenQuickGuide: true }).catch((err) => {
      alertError(err, { function: 'setHasSeenQuickGuid', component: 'SearchPromptPage' });
    });
  };

  // Handle search
  const handleSearch = (searchId: string) => {
    sendSearchParams(searchId, dataRole ?? '');
    navigate(`/${dataRoleUrlParam}/search/${searchId}`);
  };

  // Handle search
  const onSearch = () => {
    // Create a new search
    createNewSearch()
      .then(({ searchId }) => {
        handleSearch(searchId);
        incrementUserSearchCount()
          .then((newCount) => {
            posthog.setPersonProperties({ search_count: newCount });
          })
          .catch((err) => {
            alertError(err, {
              function: 'incrementUserSearchCount',
              component: 'SearchPromptPage',
            });
          });
      })
      .catch((error) => {
        alertError(error, { function: 'onSearch', component: 'SearchPromptPage' });
        toast.error(t('errors.searchFailed'));
      });
  };

  // Handle search history modal
  const handleSearchHistoryModalStatus = () => {
    setSearchHistoryModalOpened(!isSearchHistoryModalOpened);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen w-screen">
        <Icons.LogoText className="h-5" />
        <div className="h-2" />
        {showQuickGuide ? (
          <OnboardingModal setHasSeenQuickGuid={setHasSeenQuickGuid} />
        ) : (
          <>
            <DataRoleToggle />
            <div className="h-5" />
            <div className="flex flex-col  px-2 w-[1200px] max-w-[100%]">
              <SearchBar onSearch={onSearch} onOpenHistoryModal={handleSearchHistoryModalStatus} />
              <div className="px-4 min-h-64">
                {isInitialized && <ExamplePrompts onSearch={onSearch} />}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="fixed right-10 top-10 z-30">
        <UserDropDown />
      </div>
      {isSearchHistoryModalOpened && (
        <SearchHistoryModal onClose={handleSearchHistoryModalStatus} onSearch={onSearch} />
      )}
      <Feedback />
      {!showQuickGuide && <HelpButton />}
      <ToastContainer className="pointer-events-auto" />
      <LoadingCover isLoading={isCreatingSearch} />
    </>
  );
};
