import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';
import { clearParams } from '../../state/actions/searchParams';
import { SearchBar } from '../SearchBar/SearchBar';
import UserDropDown from '../UserDropDown/UserDropDown';
import { useDataRole } from '../../state/hooks/useDataRole';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';

type TopBarProps = {
  onSearch: () => void;
  showBackButtonLink?: string;
  originalSearchId?: string;
  onOpenSearchHistoryModal: () => void;
  setLeftButtonActive: (active: boolean) => void;
  leftButtonActive: boolean;
  leftButtonTitle?: string;
  setRightButtonActive: (active: boolean) => void;
  rightButtonActive: boolean;
  rightButtonTitle?: string;
};

export const TopBar = forwardRef<HTMLHeadElement, TopBarProps>(
  (
    {
      onSearch,
      showBackButtonLink,
      onOpenSearchHistoryModal,
      setLeftButtonActive,
      leftButtonActive,
      leftButtonTitle,
      setRightButtonActive,
      rightButtonActive,
      rightButtonTitle,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { dataRoleUrlParam } = useDataRole();

    const onGoHome = () => {
      posthog.capture(POSTHOG_EVENT.GO_TO_HOME);
      clearParams();
    };

    const onGotoResults = () => {
      posthog.capture(POSTHOG_EVENT.GO_TO_RESULTS);
    };

    return (
      <header ref={ref} className="fixed top-0 z-10 ">
        <div className="flex items-end pt-6 bg-qura-bg ">
          <div className="flex w-screen pl-3 pr-7 md:pl-7 md:pr-7 items-center gap-2 md:gap-7 relative">
            <div className="flex flex-col">
              <Link
                to={dataRoleUrlParam ? `/${dataRoleUrlParam}` : '/'}
                onClick={onGoHome}
                className="hidden md:block">
                <Icons.LogoText className="w-20" />
              </Link>
              <Link
                to={showBackButtonLink ?? '/'}
                onClick={showBackButtonLink ? onGotoResults : onGoHome}
                className="md:hidden p-2">
                <Icons.Arrow className="w-3 h-3 rotate-90 text-qura-neutral-balanced" />
              </Link>
              {showBackButtonLink && (
                <div className="self-start h-0 hidden md:block">
                  <Link
                    className="absolute flex mt-1 text-black gap-1 items-center"
                    to={showBackButtonLink}
                    relative="path"
                    onClick={onGotoResults}>
                    <Icons.Arrow className="w-3 h-3 rotate-90 text-qura-neutral-balanced" />
                    <span className="text-sm text-qura-neutral-balanced">
                      {t('layout.allResults')}
                    </span>
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <SearchBar onSearch={onSearch} onOpenHistoryModal={onOpenSearchHistoryModal} />
            </div>
            <div className="hidden md:block">
              <UserDropDown />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-1 md:hidden mt-2">
          {leftButtonTitle && (
            <button
              className="flex self-start mx-6 items-center bg-qura-bg text-qura-neutral-balanced rounded-full gap-1 px-2 py-0.5 text-sm"
              onClick={() => setLeftButtonActive(!leftButtonActive)}>
              <p>{leftButtonTitle}</p>
              <Icons.Arrow
                className={`${leftButtonActive ? 'rotate-90' : '-rotate-90'} transition-transform h-[7px]`}
              />
            </button>
          )}
          {rightButtonTitle && (
            <button
              className="flex self-start mx-6 items-center bg-qura-bg text-qura-neutral-balanced rounded-full gap-1 px-2 py-0.5 text-sm"
              onClick={() => setRightButtonActive(!rightButtonActive)}>
              <Icons.Arrow
                className={`${rightButtonActive ? '-rotate-90' : 'rotate-90'} transition-transform h-[7px]`}
              />
              <p>{rightButtonTitle}</p>
            </button>
          )}
        </div>
        <div className="h-6 md:block hidden" />
      </header>
    );
  },
);
