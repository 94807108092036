import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';

// ================ SEARCH SCHEMAS ================
const searchFilterSchema = z
  .object({
    tag_ids: z.array(z.string()).default([]),
    after_date: z
      .tuple([z.number().int(), z.number().int(), z.number().int()])
      .nullable()
      .default(null),
    before_date: z
      .tuple([z.number().int(), z.number().int(), z.number().int()])
      .nullable()
      .default(null),
    document_ids: z.array(z.string()).default([]),
  })
  .transform(convertSnakeToCamelCaseObject);

const searchModeSchema = z
  .object({
    no_chunks: z.boolean().default(false),
    no_ai_filter: z.boolean().default(false),
    params: z.record(z.any()).default({}),
  })
  .transform(convertSnakeToCamelCaseObject);

export const searchParamsSchema = z
  .object({
    query: z.string(),
    max_documents: z.number().int().default(25),
    max_chunks_per_document: z.number().int().default(5),
    filter: searchFilterSchema,
    search_mode: searchModeSchema,
    data_role: z.string().nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type SearchParams = z.infer<typeof searchParamsSchema>;

export const stageSchema = z.record(
  z.string(),
  z.object({
    weight: z.number().int(),
    status: z.enum(['pending', 'ongoing', 'completed', 'error']),
  }),
);

export const searchProgressSchema = z
  .object({
    complete: z.boolean(),
    stages: stageSchema,
    searched_documents: z.number().int().nullable().default(0),
    discovered_chunks: z.number().int().nullable().default(0),
    relevant_chunks: z.number().int().nullable().default(0),
    relevant_documents: z.number().int().nullable().default(0),
    search_score: z.number().nullable().default(null),
    misc: z.record(z.any()).nullable().default({}),
  })
  .transform(convertSnakeToCamelCaseObject);

export type SearchProgress = z.infer<typeof searchProgressSchema>;

// ================ RESULT SCHEMAS ================

export const BBSchema = z
  .object({
    x0: z.number(),
    y0: z.number(),
    x1: z.number(),
    y1: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type BB = z.infer<typeof BBSchema>;

const pageModificationsSchema = z
  .object({
    page_number: z.number().int(),
    highlights: z.array(
      z
        .object({
          rect: BBSchema,
          highlight_type: z.enum(['text', 'keyword']).default('text'),
        })
        .transform(convertSnakeToCamelCaseObject),
    ),
    cropping: BBSchema.nullable(),
    width: z.number(),
    height: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type PageModifications = z.infer<typeof pageModificationsSchema>;

export const fileModificationsSchema = z
  .object({
    page_selection: z.array(z.number()).nullable(),
    page_overrides: z.array(pageModificationsSchema).nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);
export type FileModifications = z.infer<typeof fileModificationsSchema>;

export const resultChunkSchema = z
  .object({
    chunk_id: z.string(),
    document_id: z.string(),
    page_id: z.string(),
    document_unit_id: z.string(),
    page_number: z.number().int(),
    excerpt: fileModificationsSchema,
    excerpt_width: z.number(),
    excerpt_height: z.number(),
    scale_factor: z.number().default(1.2),
    relevance_score: z.number(),
    height_offset: z.number(),
    highlight_color: z.tuple([z.number(), z.number(), z.number()]).nullable().default(null),
    flags: z.array(z.string()).default([]),
    misc: z.record(z.any()).default({}),
  })
  .transform(convertSnakeToCamelCaseObject);

export type ResultChunk = z.infer<typeof resultChunkSchema>;

export const resultDocumentSchema = z
  .object({
    document_id: z.string(),
    document_unit_id: z.string(),
    legal_id: z.string().nullable().default(null),
    relevance_score: z.number(),
    page_url: z.string().nullable().default(null),
    collected_at: z.number().int(),
    file_props: fileModificationsSchema,
    title: z.string(),
    tag_labels: z.array(z.string()),
    tag_ids: z.array(z.string()),
    year: z.number().int().nullable().default(null),
    month: z.number().int().nullable().default(null),
    day: z.number().int().nullable().default(null),
    language: z.string(),
    page_count: z.number().int(),
    output_chunks: z.array(resultChunkSchema),
    flags: z.array(z.string()).default([]),
    explanation: z.string().nullable().default(null),
    ai_tags: z.array(z.string()).default([]),
    misc: z.record(z.any()).default({}),
  })
  .transform(convertSnakeToCamelCaseObject);

export type ResultDocument = z.infer<typeof resultDocumentSchema>;

// ================ WEBSOCKET SCHEMAS ================
const createOutputSchema = <T extends string, C extends z.ZodTypeAny>(
  contentType: T,
  content: C,
) => {
  return z.object({
    search_id: z.string(),
    trace_id: z.string(),
    action_ids: z.array(z.string()),
    content_type: z.literal(contentType),
    content: content,
  });
};

const searchIdOutputSchema = createOutputSchema('search_id', z.any()).transform(
  convertSnakeToCamelCaseObject,
);

export const searchResultSchema = z
  .object({
    ingredients: z.array(z.string()),
    documents: z.array(resultDocumentSchema),
    progress: searchProgressSchema,
    search_action: searchParamsSchema.optional(),
    filter_selection: searchFilterSchema.optional().nullable().default(null),
    query_keywords: z.array(z.string()).default([]),
  })
  .transform(convertSnakeToCamelCaseObject);

export type SearchResult = z.infer<typeof searchResultSchema>;

const searchResultOutputSchema = createOutputSchema('result', searchResultSchema).transform(
  convertSnakeToCamelCaseObject,
);
const searchShutdownOutputSchema = createOutputSchema('shutdown', z.any()).transform(
  convertSnakeToCamelCaseObject,
);
const searchErrorOutputSchema = createOutputSchema(
  'error',
  z
    .object({
      code: z.string().optional(),
      error: z.record(z.any()),
    })
    .transform(convertSnakeToCamelCaseObject),
).transform(convertSnakeToCamelCaseObject);

export const webSocketOutputSchema = z.union([
  searchIdOutputSchema,
  searchErrorOutputSchema,
  searchResultOutputSchema,
  searchShutdownOutputSchema,
]);
