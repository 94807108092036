import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface DeveloperState {
  textEntries: Record<string, string>;
}

const initialState: DeveloperState = {
  textEntries: {},
};

export const useDeveloperStore = create<DeveloperState>()(
  devtools(
    immer(
      subscribeWithSelector(() => ({
        ...initialState,
      })),
    ),
  ),
);
