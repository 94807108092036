import { useQuery } from '@tanstack/react-query';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

const _getCompanyFromSignupToken = httpsCallable(functions, 'getCompanyFromSignupToken');

async function getCompanyFromSignupToken(token: string | null): Promise<string> {
  try {
    const result = await _getCompanyFromSignupToken({ token });

    if (!result.data) {
      throw new Error('No data returned from the function.');
    }

    if (typeof result.data !== 'string') {
      throw new Error('The data returned from the function is not a string.');
    }

    return result.data;
  } catch (error) {
    alertError(
      error,
      {
        function: 'getCompanyFromSignupToken',
        message: 'An error occurred while fetching the company.',
      },
      { token },
    );
    throw error;
  }
}

export const useCompanyFromSignupToken = (token: string | null) => {
  return useQuery({
    queryKey: ['company', token],
    queryFn: () => getCompanyFromSignupToken(token),
  });
};
