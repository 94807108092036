import { useQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { alertError } from '../../../utils/alertError';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { FileModifications } from '../commonSchemas';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';

export const convertFileModificationsToSnakeCase = (
  fileModifications: FileModifications,
): Record<string, any> => {
  return {
    file_modifications: convertBackToSnakeCaseRecursive(fileModifications),
  };
};

export const getFile = async (
  documentId: string,
  documentUnitId: string,
  fileModifications?: FileModifications,
): Promise<Blob> => {
  try {
    const token = await getAuthToken();
    const url = `${REST_API_URL}/database/${documentUnitId}/document/${documentId}`;
    const options: AxiosRequestConfig = {
      ...standardAxiosOptions(token),
      responseType: 'blob',
      timeout: 20_000,
    };
    let response;
    if (fileModifications) {
      response = await axios.post(
        url,
        convertFileModificationsToSnakeCase(fileModifications),
        options,
      );
    } else {
      response = await axios.get(url, options);
    }

    if (!(response.data instanceof Blob)) {
      throw new Error('Excerpt is not a Blob');
    }
    return response.data;
  } catch (error) {
    alertError(error, { function: 'getFile' }, { documentId, fileModifications });
    throw error;
  }
};

export const useFile = (
  documentId: string,
  documentUnitId: string,
  fileModifications: FileModifications,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['file', documentId, ...Object.values(fileModifications).flat()],
    queryFn: async () => getFile(documentId, documentUnitId, fileModifications),
    throwOnError: false,
    retryDelay: 2000,
    enabled,
  });
};
