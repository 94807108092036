import { doc, setDoc } from 'firebase/firestore';
import { ResultDocument } from '@/services/api/commonSchemas';
import { db } from '../firebaseConfig';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

export async function setViewedDocument(searchId: string, document: ResultDocument) {
  try {
    const viewedDocRef = doc(db, 'searches', searchId, 'viewedDocuments', document.documentId);
    await setDoc(viewedDocRef, convertBackToSnakeCaseRecursive(document));
  } catch (error) {
    alertError(
      error,
      { function: 'setViewedDocument' },
      { searchId, documentId: document.documentId },
    );
  }
}
