import { useMemo } from 'react';
import posthog from 'posthog-js';

export const useFeatureFlags = () => {
  return useMemo(() => {
    return {
      developerMode: posthog.isFeatureEnabled('developer-mode'),
      legacyBrowserFlag: posthog.isFeatureEnabled('legacy-browser'),
      keywordHighlighting: posthog.isFeatureEnabled('keyword-highlighting'),
    };
  }, []);
};
