import { httpsCallable } from 'firebase/functions';
import { z } from 'zod';
import { functions } from '../firebaseConfig';
import posthog from 'posthog-js';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { alertError } from '@/utils/alertError';

const _verifyEmail = httpsCallable(functions, 'verifyEmail');

const VerifyEmailResponseSchema = z.object({
  isValid: z.boolean(),
  idToken: z.string(),
});

export type VerifyEmailResponse = z.infer<typeof VerifyEmailResponseSchema>;

export async function verifyEmail(verificationToken: string) {
  try {
    const result = await _verifyEmail({ verificationToken });
    const response = VerifyEmailResponseSchema.parse(result.data);
    if (response.isValid) {
      posthog.capture(POSTHOG_EVENT.NEW_EMAIL_VERIFICATION, { verificationToken });
    }
    return response;
  } catch (error) {
    alertError(error, { function: 'verifyEmail' }, { verificationToken });
    throw error;
  }
}
