import { useEffect } from 'react';
import i18n from '../services/i18next/i18nextConfig';
import { useUserDataStore } from '../services/firebase/state/userDataStore';
import { useDataRole } from '@/features/Search/state/hooks/useDataRole';

export const SetUserLanguage = () => {
  const { data } = useUserDataStore();
  const { language } = useDataRole();

  useEffect(() => {
    if (!data) return;

    const { language: defaultLanguage } = data.storedUser;

    i18n.changeLanguage(language ?? defaultLanguage);
  }, [data, language]);

  return null;
};
