import * as React from "react";
const SvgAi = (props) => /* @__PURE__ */ React.createElement("svg", { width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  mixBlendMode: "screen"
}, d: "M7.78664 5.84168C7.78664 5.70407 7.67508 5.59251 7.53746 5.59251C6.71176 5.59251 6.0424 4.92315 6.0424 4.09744C6.0424 3.95983 5.93084 3.84827 5.79322 3.84827C5.65561 3.84827 5.54405 3.95983 5.54405 4.09744C5.54405 4.92315 4.87469 5.59251 4.04898 5.59251C3.91136 5.59251 3.7998 5.70407 3.7998 5.84168C3.7998 5.9793 3.91136 6.09086 4.04898 6.09086C4.87469 6.09086 5.54405 6.76022 5.54405 7.58593C5.54405 7.72354 5.65561 7.8351 5.79322 7.8351C5.93084 7.8351 6.0424 7.72354 6.0424 7.58593C6.0424 6.76022 6.71176 6.09086 7.53746 6.09086C7.67508 6.09086 7.78664 5.9793 7.78664 5.84168Z", fill: "#E8E8E8" }), /* @__PURE__ */ React.createElement("path", { d: "M7.78664 5.84168C7.78664 5.70407 7.67508 5.59251 7.53746 5.59251C6.71176 5.59251 6.0424 4.92315 6.0424 4.09744C6.0424 3.95983 5.93084 3.84827 5.79322 3.84827C5.65561 3.84827 5.54405 3.95983 5.54405 4.09744C5.54405 4.92315 4.87469 5.59251 4.04898 5.59251C3.91136 5.59251 3.7998 5.70407 3.7998 5.84168C3.7998 5.9793 3.91136 6.09086 4.04898 6.09086C4.87469 6.09086 5.54405 6.76022 5.54405 7.58593C5.54405 7.72354 5.65561 7.8351 5.79322 7.8351C5.93084 7.8351 6.0424 7.72354 6.0424 7.58593C6.0424 6.76022 6.71176 6.09086 7.53746 6.09086C7.67508 6.09086 7.78664 5.9793 7.78664 5.84168Z", fill: "#E8E8E8", style: {
  mixBlendMode: "screen"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  mixBlendMode: "screen"
}, d: "M13.7705 5.44007C13.7705 5.21988 13.592 5.04138 13.3718 5.04138C13.1517 5.04138 12.9732 5.21988 12.9732 5.44007C12.9732 7.42143 11.3666 9.02822 9.38501 9.02822C9.16486 9.02822 8.98633 9.20675 8.98633 9.4269C8.98633 9.64705 9.16486 9.82558 9.38501 9.82558C11.3667 9.82558 12.9732 11.4316 12.9732 13.4137C12.9732 13.6339 13.1517 13.8124 13.3718 13.8124C13.592 13.8124 13.7705 13.6339 13.7705 13.4137C13.7705 11.4316 15.3765 9.82558 17.3587 9.82558C17.5788 9.82558 17.7574 9.64705 17.7574 9.4269C17.7574 9.20675 17.5788 9.02822 17.3587 9.02822C15.3766 9.02822 13.7705 7.42146 13.7705 5.44007Z", fill: "#E8E8E8" }), /* @__PURE__ */ React.createElement("path", { d: "M13.7705 5.44007C13.7705 5.21988 13.592 5.04138 13.3718 5.04138C13.1517 5.04138 12.9732 5.21988 12.9732 5.44007C12.9732 7.42143 11.3666 9.02822 9.38501 9.02822C9.16486 9.02822 8.98633 9.20675 8.98633 9.4269C8.98633 9.64705 9.16486 9.82558 9.38501 9.82558C11.3667 9.82558 12.9732 11.4316 12.9732 13.4137C12.9732 13.6339 13.1517 13.8124 13.3718 13.8124C13.592 13.8124 13.7705 13.6339 13.7705 13.4137C13.7705 11.4316 15.3765 9.82558 17.3587 9.82558C17.5788 9.82558 17.7574 9.64705 17.7574 9.4269C17.7574 9.20675 17.5788 9.02822 17.3587 9.02822C15.3766 9.02822 13.7705 7.42146 13.7705 5.44007Z", fill: "#E8E8E8", style: {
  mixBlendMode: "screen"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  mixBlendMode: "screen"
}, d: "M6.59401 10.6243C6.59401 10.4041 6.41551 10.2256 6.19533 10.2256C5.97514 10.2256 5.79664 10.4041 5.79664 10.6243C5.79664 11.9454 4.72567 13.0164 3.40454 13.0164C3.18436 13.0164 3.00586 13.1949 3.00586 13.4151C3.00586 13.6352 3.18436 13.8137 3.40454 13.8137C4.72567 13.8137 5.79664 14.8847 5.79664 16.2058C5.79664 16.426 5.97514 16.6045 6.19533 16.6045C6.41551 16.6045 6.59401 16.426 6.59401 16.2058C6.59401 14.8847 7.66499 13.8137 8.98611 13.8137C9.20627 13.8137 9.3848 13.6352 9.3848 13.4151C9.3848 13.1949 9.20627 13.0164 8.98611 13.0164C7.66499 13.0164 6.59401 11.9454 6.59401 10.6243Z", fill: "#E8E8E8" }), /* @__PURE__ */ React.createElement("path", { d: "M6.59401 10.6243C6.59401 10.4041 6.41551 10.2256 6.19533 10.2256C5.97514 10.2256 5.79664 10.4041 5.79664 10.6243C5.79664 11.9454 4.72567 13.0164 3.40454 13.0164C3.18436 13.0164 3.00586 13.1949 3.00586 13.4151C3.00586 13.6352 3.18436 13.8137 3.40454 13.8137C4.72567 13.8137 5.79664 14.8847 5.79664 16.2058C5.79664 16.426 5.97514 16.6045 6.19533 16.6045C6.41551 16.6045 6.59401 16.426 6.59401 16.2058C6.59401 14.8847 7.66499 13.8137 8.98611 13.8137C9.20627 13.8137 9.3848 13.6352 9.3848 13.4151C9.3848 13.1949 9.20627 13.0164 8.98611 13.0164C7.66499 13.0164 6.59401 11.9454 6.59401 10.6243Z", fill: "#E8E8E8", style: {
  mixBlendMode: "screen"
} }));
export default SvgAi;
