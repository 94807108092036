import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataRole } from '@/services/firebase/functions/getStoredUser';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import i18n from '@/services/i18next/i18nextConfig';

type DataRoleResult = {
  isLoading: boolean;
  error: 'logged-out' | 'not-selected' | null;
  dataRole: string | null;
  dataRoleUrlParam: string | null;
  examplePrompts: string[] | null;
  language: 'sv' | 'en' | null;
};

export const useDataRole = (): DataRoleResult => {
  const { dataRole: dataRoleUrlParam } = useParams();
  const { dataRoleLookup, isInitialized } = useUserDataStore();

  const result = useMemo<DataRoleResult>(() => {
    if (!dataRoleLookup) {
      return {
        isLoading: !isInitialized,
        error: isInitialized ? 'logged-out' : null,
        dataRole: null,
        dataRoleUrlParam: dataRoleUrlParam ?? null,
        examplePrompts: null,
        language: null,
      };
    }

    const currentDataRole = ((dataRoleUrlParam && dataRoleLookup[dataRoleUrlParam]
      ? dataRoleLookup[dataRoleUrlParam]
      : Object.values(dataRoleLookup)[0]) ?? null) as DataRole | null;

    if (!currentDataRole) {
      return {
        isLoading: false,
        error: 'not-selected',
        dataRole: null,
        dataRoleUrlParam: null,
        examplePrompts: null,
        language: null,
      };
    }

    return {
      isLoading: false,
      error: null,
      dataRole: currentDataRole.roleName,
      dataRoleUrlParam: currentDataRole.urlParam ?? null,
      examplePrompts: currentDataRole.examplePrompts,
      language: currentDataRole.language,
    };
  }, [dataRoleLookup, dataRoleUrlParam, isInitialized]);

  useEffect(() => {
    if (result.language) {
      i18n.changeLanguage(result.language);
    }
  }, [result.language]);

  return result;
};
