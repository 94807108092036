import { useEffect } from 'react';
import posthog from 'posthog-js';
import { useUserDataStore } from '../firebase/state/userDataStore';

export const IdentifyPosthog = () => {
  const { data } = useUserDataStore();

  useEffect(() => {
    if (data) {
      posthog.identify(data.authUser.uid, {
        userId: data.authUser.uid,
        companyId: data.storedUser.companyId,
        language: data.storedUser.language,
      });
    }
  }, [data]);

  return null;
};
