import { POSTHOG_EVENT } from '@/services/posthog/events';
import * as Sentry from '@sentry/react';
import { signOut } from 'firebase/auth';
import posthog from 'posthog-js';
import { alertError } from '../../../utils/alertError';
import { auth } from '../firebaseConfig';

export async function logout() {
  try {
    Sentry.setUser(null);
    posthog.capture(POSTHOG_EVENT.LOGOUT);
    posthog.reset();
    await signOut(auth);
  } catch (error) {
    alertError(error, { function: 'logout' });
  }
}
