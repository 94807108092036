import { useNavigate } from 'react-router-dom';
import { useDataRole } from '../../state/hooks/useDataRole';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';

export const DataRoleToggle = () => {
  const { dataRoleUrlParam } = useDataRole();
  const { dataRoleLookup } = useUserDataStore();
  const navigate = useNavigate();
  const selectedDataRole = dataRoleUrlParam;
  const visible = dataRoleLookup && Object.keys(dataRoleLookup).length > 1;

  // Update the data role in the URL
  const selectDataRole = (roleId: string) => {
    navigate(`/${roleId}`);
  };

  if (!visible) return null;

  return (
    <div className="flex flex-row items-center p-1 gap-2 bg-[#F8F8F8] border border-[#F1F1F1] rounded-full">
      {Object.entries(dataRoleLookup).map(([roleId, roleData]) => (
        <button
          key={roleId}
          className={`flex justify-center items-center px-2.5 py-2 rounded-full transition-colors duration-300 ${
            selectedDataRole === roleId
              ? 'bg-black text-white'
              : 'text-[#0C0C0C] hover:bg-[#F1F1F1]'
          }`}
          onClick={() => selectDataRole(roleId)}>
          <span className="text-xs font-medium leading-[89%] tracking-tight">{roleData.label}</span>
        </button>
      ))}
    </div>
  );
};
