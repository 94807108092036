import { useCallback, useEffect } from 'react';
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { alertError } from './alertError';
import { useUserDataStore } from '../services/firebase/state/userDataStore';
import { db } from '../services/firebase/firebaseConfig';

const RELOAD_INTERVAL = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

export const CheckForceReload = () => {
  const { data } = useUserDataStore();

  const reload = useCallback(async (docRef: DocumentReference<DocumentData>) => {
    const auth = getAuth();
    if (!auth.currentUser) return;

    try {
      await setDoc(docRef, { shouldReload: false, lastReload: new Date() }, { merge: true });
      await auth.currentUser.getIdToken(true);
      await auth.currentUser.reload();
      window.location.reload();
    } catch (error) {
      alertError(error, { function: 'reload', component: 'CheckForceReload' }, { docRef });
    }
  }, []);

  const checkReload = useCallback(
    async (docRef: DocumentReference<DocumentData>) => {
      const auth = getAuth();
      if (!auth.currentUser) return;

      try {
        const docSnapshot = await getDoc(docRef);
        const data = docSnapshot.data();
        if (data?.shouldReload) {
          await reload(docRef);
        }
      } catch (error) {
        alertError(error, { function: 'checkReload', component: 'CheckForceReload' }, { docRef });
      }
    },
    [reload],
  );

  useEffect(() => {
    if (!data) return;

    const docRef = doc(db, 'usersEditable', data.authUser.uid);
    let reloadTimer: NodeJS.Timeout;

    const setupReloadTimer = () => {
      reloadTimer = setTimeout(() => {
        reload(docRef);
      }, RELOAD_INTERVAL);
    };

    setupReloadTimer();

    const unsubscribe = onSnapshot(
      docRef,
      () => {
        checkReload(docRef)
          .then(() => {
            clearTimeout(reloadTimer);
            setupReloadTimer();
          })
          .catch((error) => {
            alertError(
              error,
              { function: 'checkReload', component: 'CheckForceReload' },
              { docRef },
            );
          });
      },
      (error) => {
        alertError(error, { function: 'onSnapshot', component: 'CheckForceReload' }, { docRef });
      },
    );

    return () => {
      unsubscribe();
      clearTimeout(reloadTimer);
    };
  }, [data, checkReload, reload]);

  return null;
};
