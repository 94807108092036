import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

export interface UserSettings {
  language: 'en' | 'sv';
  hasSeenQuickGuide: boolean;
}

export const setUserSettings = async (settings: Partial<UserSettings>) => {
  try {
    const authUser = getAuth().currentUser;
    const userId = authUser?.uid;
    if (!userId) return;

    const usersEditableRef = doc(db, 'usersEditable', userId);
    await updateDoc(usersEditableRef, settings);
  } catch (error) {
    alertError(error, { function: 'setUserSettings' }, { settings });
  }
};
