import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/fonts.scss';
import './styles/global.scss';
import './styles/tailwind.scss';

import './services/posthog/init';
import './services/sentry/init';

import 'core-js/stable';
import ReactDOM from 'react-dom/client';
import App from './App';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
