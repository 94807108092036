import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

export async function signInWithToken(token: string) {
  try {
    await signInWithCustomToken(auth, token);
  } catch (error) {
    alertError(error, { function: 'signInWithToken' }, { token });
  }
}
