import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';
import { useInfiniteQuery } from '@tanstack/react-query';
import { db } from '../firebaseConfig';
import { resultDocumentSchema, searchParamsSchema } from '@/services/api/commonSchemas';
import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { getAuth } from 'firebase/auth';
import { alertError } from '@/utils/alertError';

const searchActionSchema = z
  .object({
    submitted_at: z.number(),
    content: searchParamsSchema,
    search_id: z.string(),
  })
  .transform(convertSnakeToCamelCaseObject);

const searchHistoryOptionSchema = z.object({
  search: searchActionSchema,
  documents: z.array(resultDocumentSchema),
});

export type SearchHistoryOption = z.infer<typeof searchHistoryOptionSchema>;

async function getFullHistorySearches(lastDoc: QueryDocumentSnapshot | null) {
  try {
    const user = getAuth().currentUser;

    if (!user) {
      throw new Error('No user found');
    }

    const searchesRef = collection(db, 'searches');
    let q = query(
      searchesRef,
      where('user_id', '==', user.uid),
      where('hidden', '!=', true),
      orderBy('created_at', 'desc'),
      limit(20),
    );

    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    const searchesSnapshot = await getDocs(q);

    const searches: SearchHistoryOption[] = [];

    if (searchesSnapshot.empty) {
      return { searches, pageParam: null };
    }

    const lastVisible = searchesSnapshot.docs[searchesSnapshot.docs.length - 1];

    await Promise.all(
      searchesSnapshot.docs.map(async (searchDoc) => {
        const actionsRef = collection(searchDoc.ref, 'actions');
        const lastActionSnapshot = await getDocs(
          query(
            actionsRef,
            where('content_type', '==', 'search'),
            orderBy('submitted_at', 'desc'),
            limit(1),
          ),
        );

        if (!lastActionSnapshot.empty) {
          const viewedDocumentsRef = collection(searchDoc.ref, 'viewedDocuments');
          const viewedDocumentsSnapshot = await getDocs(viewedDocumentsRef);

          const data = {
            search: lastActionSnapshot.docs[0].data(),
            documents: viewedDocumentsSnapshot.empty
              ? []
              : viewedDocumentsSnapshot.docs.map((doc) => doc.data()),
          };

          searches.push(searchHistoryOptionSchema.parse(data));
        } else {
          alertError(
            'No actions found for search',
            {
              function: 'getFullHistorySearches',
              message: 'No actions found for search',
            },
            { searchId: searchDoc.id },
          );
        }
      }),
    );

    return { searches, pageParam: lastVisible };
  } catch (error) {
    alertError(
      error,
      {
        function: 'getFullHistorySearches',
        message: 'An error occurred while fetching the search history.',
      },
      { lastDoc },
    );
    throw error;
  }
}

export const useFullHistoryInfiniteScroll = () => {
  return useInfiniteQuery({
    initialPageParam: null as QueryDocumentSnapshot | null,
    queryKey: ['recent_searches'],
    queryFn: ({ pageParam }) => getFullHistorySearches(pageParam),
    getNextPageParam: (lastPage) => lastPage.pageParam,
  });
};
