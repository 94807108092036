import { usePostHog } from 'posthog-js/react';
import { FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addTags, removeTags } from '../../state/actions/searchParams';
import { useTagCount } from '../../state/hooks/useTagCount';
import { useSearchStore } from '../../state/searchStore';
import { TagNode } from '@/services/api/actions/useAvailableFilterTags';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';

type Props = {
  tag: TagNode;
  parentTag: TagNode | null;
  renderSubTag?: (parentOption: TagNode, parentTag: TagNode) => ReactNode;
};

const TagButton: FC<Props> = ({ tag, renderSubTag }) => {
  const posthog = usePostHog();
  const leafTags = useMemo(() => {
    const leafTags = [];
    const visitedTags = new Set<string>();
    const tagQueue = [tag];

    while (tagQueue.length > 0) {
      const tag = tagQueue.pop()!;
      if (visitedTags.has(tag.tagId)) continue;
      visitedTags.add(tag.tagId);
      tagQueue.push(...tag.subNodes);
      if (tag.subNodes.length == 0) {
        leafTags.push(tag.tagId);
      }
    }

    return leafTags;
  }, [tag]);

  const [hideSuboptions, setHideSuboptions] = useState(true);
  const selection = useSearchStore<'none' | 'some' | 'all'>((state) => {
    if (leafTags.every((tagId) => state.currentParams.filter?.tagIds.includes(tagId))) {
      return 'all';
    }
    if (leafTags.some((tagId) => state.currentParams.filter?.tagIds.includes(tagId))) {
      return 'some';
    }
    return 'none';
  });

  const { searchId = '' } = useParams();
  const tagCountMap = useTagCount(searchId);
  const tagCount = tagCountMap?.[tag.tagId] || 0;

  const sortedSubNodes = useMemo(
    () => tag.subNodes.sort((a, b) => a.weight - b.weight),
    [tag.subNodes],
  );

  const selectClick = () => {
    if (selection == 'all') {
      removeTags(leafTags);
      posthog.capture(POSTHOG_EVENT.FILTER_TAG_DESELECT, { tagId: tag.tagId, searchId });
    } else {
      posthog.capture(POSTHOG_EVENT.FILTER_TAG_SELECT, { tagId: tag.tagId, searchId });
      addTags(leafTags);
    }
  };

  const toggleHidden: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setHideSuboptions((hide) => !hide);
  };

  return (
    <>
      <div
        className={`flex flex-1 gap-2 first-letter items-center justify-left cursor-pointer`}
        onClick={selectClick}>
        <div
          className={`rounded-md flex items-center flex-1 cursor-pointer hover:bg-qura-neutral-ghost ${selection !== 'none' ? 'bg-qura-neutral-ghost' : ''}`}>
          <div className="w-[10px] h-[10px] ml-2 my-[5px] rounded-full border border-qura-neutral-jet flex justify-center items-center">
            {selection == 'all' && (
              <div className="w-[6px] h-[6px] rounded-full bg-qura-neutral-jet" />
            )}
            {selection == 'some' && (
              <div className="w-[6px] h-[2px] rounded-full bg-qura-neutral-jet" />
            )}
          </div>
          <p
            className={`flex-1 pl-2 text-xs my-[5px] ${selection !== 'none' ? '' : 'text-qura-neutral-jet mr-1'}`}>
            {tag.label}
          </p>
          {tag.subNodes.length > 0 && renderSubTag && (
            <button className="text-right hover:underline px-2 self-stretch" onClick={toggleHidden}>
              <Icons.Arrow className={`w-2.5 ${hideSuboptions ? ' ' : 'rotate-180'}`} />
            </button>
          )}
        </div>
        <div
          className={`flex text-xs justify-center items-center w-[25px] py-[5px] rounded-md text-qura-neutral-balanced bg-qura-neutral-ghost ${tagCount === 0 ? 'opacity-0' : ''}`}>
          {tagCount}
        </div>
      </div>
      {tag.subNodes.length > 0 && renderSubTag && !hideSuboptions && (
        <div className="flex">
          <div className="w-[1px] -mt-1.5 mb-3 ml-[12px] mr-[3px] bg-qura-neutral-silver" />
          <div className="flex flex-col flex-1 space-y-1.5">
            {sortedSubNodes.map((subtag) => renderSubTag(subtag, tag))}
          </div>
        </div>
      )}
    </>
  );
};

export default TagButton;
