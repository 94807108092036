import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSearchMode } from '../../state/actions/searchParams';
import { Icons } from '@/assets';

const LINE_HEIGHT = 23;
const MAX_HEIGHT = 23 * 6; // Assuming 6 lines maximum

type SearchBarInputProps = {
  query?: string;
  placeholder: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearchClick?: () => void;
  queryChanged?: (query: string) => void;
  onKeyUpDown?: (direction: 1 | -1) => void;
  searchMode: 'crossDocumentSearch' | 'inDocumentSearch';
};

export const SearchBarInput = ({
  query,
  placeholder,
  onFocus,
  onBlur,
  onSearchClick,
  queryChanged,
  onKeyUpDown,
  searchMode,
}: SearchBarInputProps) => {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaHeight, setTextareaHeight] = useState(LINE_HEIGHT);

  const updateTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${LINE_HEIGHT}px`;
      const height = Math.min(textarea.scrollHeight, MAX_HEIGHT);
      const heightShouldBe = Math.floor(height / LINE_HEIGHT) * LINE_HEIGHT;
      textarea.style.height = `${heightShouldBe}px`;
      setTextareaHeight(heightShouldBe);
    }
  }, []);

  const updateLocalQuery = useCallback(
    (query: string) => {
      updateTextareaHeight();
      queryChanged && queryChanged(query);
    },
    [updateTextareaHeight, queryChanged],
  );

  useEffect(() => {
    updateLocalQuery(query || '');
  }, [query, updateLocalQuery]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        onSearchClick && onSearchClick();
      }
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      onKeyUpDown && onKeyUpDown(e.key === 'ArrowUp' ? 1 : -1);
    }
  };

  const handleExitDocumentSearch = () => {
    setSearchMode('crossDocumentSearch');
  };

  return (
    <div className="flex relative gap-[10px] items-center " style={{ height: textareaHeight }}>
      <button onClick={onSearchClick} title="Search">
        <Icons.NewSearch />
      </button>
      {searchMode === 'inDocumentSearch' && (
        <div className="md:flex hidden items-center text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md">
          {t('searchBar.inDocumentSearch')}
          <button
            onClick={handleExitDocumentSearch}
            className="ml-2 text-gray-400 hover:text-gray-600"
            title="Exit document search">
            <Icons.Close />
          </button>
        </div>
      )}

      <textarea
        ref={textareaRef}
        onFocus={onFocus}
        onBlur={onBlur}
        value={query}
        className="flex-1 resize-none bg-transparent outline-none border-none"
        style={{ lineHeight: `${LINE_HEIGHT}px` }}
        onChange={(e) => updateLocalQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      />
    </div>
  );
};
