import { useQueries, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

const documentNameSchema = z
  .object({
    document_id: z.string(),
    legal_id: z.string(),
    title: z.string(),
    tag_ids: z.array(z.string()).default([]),
  })
  .transform(convertSnakeToCamelCaseObject);

export type DocumentName = z.infer<typeof documentNameSchema>;

async function getDocumentName(
  documentId: string,
  documentUnitId?: string,
): Promise<DocumentName | null> {
  try {
    const token = await getAuthToken();
    const response = await axios.get(
      `${REST_API_URL}/database/document/${documentId}/name?document_unit_id=${documentUnitId}`,
      standardAxiosOptions(token),
    );
    if (response.data === null) {
      return null;
    }
    return documentNameSchema.parse(response.data);
  } catch (error) {
    alertError(error, { function: 'getDocumentName' }, { documentId, documentUnitId });
    throw error;
  }
}

export const useDocumentName = (documentId: string, documentUnitId?: string) => {
  return useQuery({
    queryKey: ['document_name', documentId],
    queryFn: () => getDocumentName(documentId, documentUnitId),
    retry: false,
  });
};

export const useDocumentNames = (documentIds: string[]) => {
  return useQueries({
    queries: documentIds.map((documentId) => ({
      queryKey: ['document_name', documentId],
      queryFn: () => getDocumentName(documentId),
      retry: false,
    })),
  });
};
