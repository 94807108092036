import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthRedirect } from './features/Auth/guards/AuthRedirect';
import { ConfirmEmailEndpoint } from './features/Auth/pages/ConfirmEmailEndpoint/ConfirmEmailEndpoint';
import { EmailConfirmationPage } from './features/Auth/pages/EmailConfirmationPage/EmailConfirmationPage';
import { ForgotPasswordPage } from './features/Auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './features/Auth/pages/LoginPage/LoginPage';
import { PasswordResetPage } from './features/Auth/pages/PasswordResetPage/PasswordResetPage';
import { SignupPage } from './features/Auth/pages/SignupPage/SignupPage';
import DocumentPageGuard from './features/Search/pages/DocumentPage/DocumentPage';
import { SearchPromptPage } from './features/Search/pages/SearchPromptPage/SearchPromptPage';
import { SearchResultPage } from './features/Search/pages/SearchResultPage/SearchResultPage';
import { useSearchDepth } from './services/posthog/useSearchDepth';
import { useSentryTags } from './services/sentry/useSentryTags';
import { SetDefaultDataRole } from './utils/SetDefaultDataRole';
import { NotFound } from './features/Common/Error/404';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => {
  const { t } = useTranslation();
  const [onLoggedIn, setOnLoggedIn] = useState<string>('/');
  const [onLoggedOut, setOnLoggedOut] = useState<string>('/auth');

  useEffect(() => {
    const landedOn = window.location;
    if (!landedOn.pathname.startsWith('/auth')) {
      setOnLoggedIn(landedOn.pathname + landedOn.search);
      setOnLoggedOut('/auth');
    } else {
      setOnLoggedOut(landedOn.pathname + landedOn.search);
      setOnLoggedIn('/');
    }
  }, []);

  useSentryTags();
  useSearchDepth();
  return (
    <SentryRoutes>
      <Route
        index
        element={
          <AuthRedirect onLoggedOut={onLoggedOut} onEmailNotVerified="/auth/confirm-email">
            <SetDefaultDataRole />
          </AuthRedirect>
        }
      />
      <Route
        path="/:dataRole"
        element={
          <AuthRedirect
            onLoggedOut={onLoggedOut}
            onEmailNotVerified="/auth/confirm-email"
            requireDataRole>
            <SearchPromptPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/:dataRole/search/:searchId"
        element={
          <AuthRedirect
            onLoggedOut={onLoggedOut}
            onEmailNotVerified="/auth/confirm-email"
            requireDataRole>
            <SearchResultPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/:dataRole/document/:documentId"
        element={
          <AuthRedirect onLoggedOut={onLoggedOut} onEmailNotVerified="/auth/confirm-email">
            <DocumentPageGuard />
          </AuthRedirect>
        }
      />
      <Route
        path="/auth"
        element={
          <AuthRedirect onLoggedIn={onLoggedIn}>
            <LoginPage />
          </AuthRedirect>
        }
      />
      <Route
        path="/auth/signup"
        element={
          <AuthRedirect onLoggedIn={onLoggedIn}>
            <SignupPage />
          </AuthRedirect>
        }
      />
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/confirm-email" element={<EmailConfirmationPage />} />
      <Route path="/auth/confirm-email-endpoint" element={<ConfirmEmailEndpoint />} />
      <Route path="/auth/reset-password" element={<PasswordResetPage />} />
      <Route
        path="/error"
        element={
          <NotFound
            title={t('errors.errorPageTitle')}
            description={t('errors.errorPageDescription')}
            showFeedback
          />
        }
      />
      <Route path="*" element={<Navigate to={onLoggedIn} replace />} />
    </SentryRoutes>
  );
};
