import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { UserData } from './getUserData';
import { DataRole } from '../functions/getStoredUser';

export type UserDataState = {
  data: UserData | null;
  dataRoleLookup: Record<string, DataRole> | null;
  isInitialized: boolean;
};

const initialState: UserDataState = {
  data: null,
  isInitialized: false,
  dataRoleLookup: null,
};

export const useUserDataStore = create<UserDataState>()(
  devtools(
    immer(
      subscribeWithSelector(() => ({
        ...initialState,
      })),
    ),
  ),
);
