import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { getQuickGuideUrl, mailto } from '@/constants';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export const HelpButton = ({ hideDetails }: { hideDetails?: boolean }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onOpenQuickGuide = () => {
    posthog.capture(POSTHOG_EVENT.OPEN_QUICK_GUIDE);
  };

  const onContactTeam = () => {
    posthog.capture(POSTHOG_EVENT.CONTACT_TEAM);
  };

  return (
    <>
      <div ref={modalRef} className="flex flex-col fixed left-8 bottom-8 gap-3 z-30">
        {isModalVisible && (
          <div className="shadow-qura max-w-[250px] gap-8 bg-white pointer-events-auto flex flex-col px-6 py-7 rounded-lg">
            <div className="flex">
              <p className="text-light py-1.5 px-2.5 text-xs rounded-full bg-qura-neutral-ghost">
                {t('helpButton.modalLabel')}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <p className="text-medium">{t('helpButton.modalTitle')}</p>
                <p className="text-qura-neutral-balanced text-sm">
                  {t('helpButton.modalDescription')}
                </p>
              </div>
              <a
                href={getQuickGuideUrl(i18n.language)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onOpenQuickGuide}
                className="with-full">
                <button className={`w-full px-7 group rounded-full py-2 bg-qura-neutral-jet`}>
                  <p className="text-white">{t('helpButton.viewQuickGuide')}</p>
                </button>
              </a>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-sm">{t('helpButton.haveMoreQuestions')}</p>
              <a href={`mailto:${mailto}`} onClick={onContactTeam}>
                <button
                  className={`w-full px-7 group rounded-full p-[3px] bg-white shadow-qura hover:shadow transition-shadow `}>
                  <p className="text-black rounded-full py-[6px]">{t('helpButton.contactTeam')}</p>
                </button>
              </a>
            </div>
          </div>
        )}
        <div className="flex items-center gap-5 h-14">
          <div className="flex items-center flex-col">
            <button
              onClick={() => {
                setIsModalVisible((isVisible) => !isVisible);
              }}
              className="flex items-center justify-center h-14 w-14 bg-white rounded-full shadow-qura hover:shadow transition-shadow"
              aria-label="Help">
              <span className="text-qura-neutral-balanced font-light color-qura-neutral-balanced text-inter text-2xl">
                ?
              </span>
            </button>
          </div>
          {!isModalVisible && !hideDetails && (
            <div className="gap-0.5 gradient-box shadow-modal-light bg-white pointer-events-auto flex flex-col px-7 py-4 rounded-lg">
              <p className="text-sm ">{t('helpButton.notificationTitle')}</p>
              <p className="text-sm font-light">{t('helpButton.notificationDescription')}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
