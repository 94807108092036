import { useMemo, useState } from 'react';
import { KeywordHighlight, LinkHighlight, TextHighlight } from './Highlights';
import { BB, PageModifications } from '@/services/api/commonSchemas';
import { RelatedDocument } from '@/services/api/actions/useDocumentRelations';
import { Keyword } from '@/services/api/actions/useKeywords';
import { useFeatureFlags } from '@/services/posthog/featureFlags';

export const Overlays = ({
  focusLinksWith,
  pageModifications,
  references,
  zoom,
  keywords,
}: {
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  pageModifications?: PageModifications;
  zoom: number;
  references?: RelatedDocument[];
  keywords?: Keyword[];
}) => {
  const { keywordHighlighting } = useFeatureFlags();
  const [popupValue, setPopupValue] = useState<string | null>(null);
  const applyZoom = useMemo(
    () =>
      (rect: BB): BB => {
        return {
          x0: rect.x0 * zoom,
          x1: rect.x1 * zoom,
          y0: rect.y0 * zoom,
          y1: rect.y1 * zoom,
        };
      },
    [zoom],
  );

  const { textHighlightRects } = useMemo(() => {
    const highlights = pageModifications?.highlights;
    return {
      textHighlightRects: highlights
        ?.filter((highlight) => highlight.highlightType === 'text')
        .map((highlight) => applyZoom(highlight.rect)),
    };
  }, [pageModifications, applyZoom]);

  const zoomedReferences = useMemo(() => {
    return references?.map((reference) => ({
      ...reference,
      zoomedBbs: reference.localBbs?.map(applyZoom) ?? [],
    }));
  }, [references, applyZoom]);

  const zoomedKeywords = useMemo(() => {
    return keywords?.map((keyword) => ({
      ...keyword,
      zoomedBbs: keyword.bbs?.map(applyZoom) ?? [],
    }));
  }, [keywords, applyZoom]);

  return (
    <>
      {textHighlightRects &&
        textHighlightRects.map((rect, index) => (
          <TextHighlight key={`text-${index}`} rects={[rect]} />
        ))}
      {zoomedKeywords &&
        keywordHighlighting &&
        zoomedKeywords.map((keyword, index) => (
          <KeywordHighlight key={`keyword-${index}`} rects={keyword.zoomedBbs} />
        ))}
      {zoomedReferences &&
        zoomedReferences.map((reference, index) => {
          return (
            <LinkHighlight
              key={`link-${index}`}
              id={`link-${index}`}
              focusLinksWith={focusLinksWith}
              zoomedBbs={reference.zoomedBbs}
              reference={reference}
              popupValue={popupValue}
              setPopupValue={setPopupValue}
            />
          );
        })}
    </>
  );
};
