import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

const _sendConfirmationEmail = httpsCallable(functions, 'sendConfirmationEmail');

export async function sendConfirmationEmail(email: string): Promise<boolean> {
  try {
    const domain = window.location.origin;
    await _sendConfirmationEmail({ email, domain });
    return true;
  } catch (error) {
    alertError(error, { function: 'sendConfirmationEmail' }, { email });
    return false;
  }
}
