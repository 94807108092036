import { useTranslation } from 'react-i18next';
import { usePostHogSurvey } from '../hooks/usePostHogSurvey';
import { Icons } from '@/assets';

export const NPSFeedback = () => {
  const { survey, submit, dismiss } = usePostHogSurvey();
  const { t } = useTranslation();

  if (!survey) {
    return null;
  }

  const questionData: any = survey.questions[0];

  return (
    <div className="flex flex-col fixed right-8 bottom-8 gap-3 z-30">
      <div className="shadow-qura max-w-[380px] gap-8 bg-white pointer-events-auto flex flex-col px-12 py-8 rounded-lg items-center text-center">
        <Icons.LogoText className="h-5 text-black" />
        <div className="flex">
          <p className="text-light py-1.5 px-2.5 text-xs rounded-full bg-qura-neutral-ghost">
            {t('NPSForm.modalLabel')}
          </p>
        </div>
        <h2 className="text-md">{t(questionData.question)}</h2>
        <div className="grid grid-cols-5 gap-4">
          {new Array(questionData.scale).fill(null).map((_, index) => (
            <button
              className="shadow-qura w-12 h-12 bg-white border-[3px] border-qura-neutral-silver-light hover:bg-qura-neutral-jet hover:text-white text-qura-neutral-balanced rounded-full"
              key={index}
              onClick={() => {
                submit(index + 1);
              }}>
              {index + 1}
            </button>
          ))}
        </div>
        <div className="flex justify-between w-full">
          <p className="text-qura-neutral-balanced text-sm">{t(questionData.lowerBoundLabel)}</p>
          <p className="text-qura-neutral-balanced text-sm">{t(questionData.upperBoundLabel)}</p>
        </div>
        <button onClick={dismiss}>{t(questionData.buttonText)}</button>
      </div>
    </div>
  );
};
