import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Icons } from '@/assets';
import { forgotPassword } from '@/services/firebase/functions/forgotPassword';
import { logout } from '@/services/firebase/functions/logout';
import { alertError } from '@/utils/alertError';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';

const USER_PICTURE_SIZE = 38;
const ELEMENT_HEIGHT = 30;

const UserPictureCircle: FC = () => {
  return (
    <div
      style={{
        width: USER_PICTURE_SIZE,
        height: USER_PICTURE_SIZE,
        marginRight: (ELEMENT_HEIGHT - USER_PICTURE_SIZE) / 2,
      }}
      className="flex items-center justify-center">
      <Icons.UserPicture className="w-full h-full" />
    </div>
  );
};

const UserDropDown: FC = () => {
  const { data: userData } = useUserDataStore();
  const { t } = useTranslation();

  const changePasswordClick = () => {
    if (!userData?.authUser?.email) return;

    const forgotPasswordProcess = forgotPassword(userData.authUser.email);

    toast.promise(
      forgotPasswordProcess,
      {
        pending: t('userDropdown.pending'),
        success: t('userDropdown.success'),
        error: t('userDropdown.error'),
      },
      {
        position: 'top-left',
      },
    );
  };

  const logOutClick = () => {
    logout()
      .then(() => {
        if (window.location.href.split('://')[1].split('/')[0].split(':')[0] === 'app.qura.law') {
          window.location.replace('https://qura.law');
        }
      })
      .catch((error) => {
        alertError(error, { function: 'logout' });
      });
  };

  if (!userData) return null;

  return (
    <div className="group relative z-30">
      <div className="flex items-center">
        <div className="flex w-6 mr-3">
          <UserPictureCircle />
        </div>
        <Icons.Arrow className={`text-black h-3 w-3 transition-transform group-hover:rotate-180`} />
      </div>
      <div className="absolute top-0 -right-3 pt-11 w-72 hidden group-hover:block ">
        <div
          className={`w-72 z-10 flex flex-col gap-6 justify-start shadow-qura overflow-hidden bg-white rounded-md items-start px-4 py-5 `}>
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
              <p className="text-sm">{userData.storedUser.username}</p>
              <p className=" bg-qura-neutral-ghost px-2 py-1 rounded-full text-xs">
                {userData.company.companyName}
              </p>
            </div>
            <p className="text-xs text-qura-neutral-balanced">{userData.authUser.email}</p>
          </div>
          <div className="flex flex-col gap-2 w-full text-xs">
            <button
              className="flex items-center text-qura-neutral-balanced hover:bg-qura-neutral-ghost px-2 py-[10px] rounded-md"
              onClick={changePasswordClick}>
              <div className="flex items-center w-7">
                <Icons.Mail className="w-4 h-4" />
              </div>
              <p>{t('userDropdown.changePassword')}</p>
            </button>
            <div className="h-[0.5px] w-full bg-qura-neutral-light" />
            <button
              className="flex items-center hover:bg-qura-neutral-ghost  px-2 py-[10px] rounded-md"
              onClick={logOutClick}>
              <div className="flex items-center w-7 ml-0.5">
                <Icons.Arrow className="w-3 h-3 rotate-90 text-qura-red" />
              </div>
              <p className="font-medium text-qura-red">{t('userDropdown.logout')}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
