import posthog from 'posthog-js';
import { useSearchStore } from '../searchStore';
import { convertBackToSnakeCaseRecursive } from '@/utils/snakeToCamelCase';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { alertError } from '@/utils/alertError';
import { SearchParams } from '@/services/api/commonSchemas';
import {
  getCurrentAvailableFilterTags,
  transformTagSelection,
} from '@/services/api/actions/useAvailableFilterTags';

export const sendSearchParams = (searchId: string, dataRole: string) => {
  const state = useSearchStore.getState();

  if (state.currentParams.query.trim().length === 0) {
    return;
  }

  const ws = state.crossDocumentSearches[searchId]?.ws;
  if (!ws) {
    alertError(new Error('No websocket connection'), { function: 'sendSearchParams' });
    return;
  }
  const availableFilterTags = getCurrentAvailableFilterTags(dataRole);
  const currentParams = useSearchStore.getState().currentParams;
  const params: SearchParams = {
    ...currentParams,
    filter: {
      ...currentParams.filter,
      tagIds: availableFilterTags
        ? transformTagSelection('parent-only', currentParams.filter.tagIds, availableFilterTags)
        : [],
    },
    searchMode: {
      ...currentParams.searchMode,
    },
    dataRole,
  };

  const payload = convertBackToSnakeCaseRecursive({
    content_type: 'search',
    content: params,
  });

  posthog.capture(POSTHOG_EVENT.SEARCH_START, {
    params: params,
    searchId,
  });

  ws.send(JSON.stringify(payload));
};
