import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useUserDataStore } from '../firebase/state/userDataStore';

export const useSentryTags = () => {
  const { data } = useUserDataStore();

  useEffect(() => {
    Sentry.setUser({
      email: data?.authUser.email || undefined,
      id: data?.authUser.uid,
      username: data?.storedUser.username,
    });
    Sentry.setTag('user_uid', data?.authUser.uid);
    Sentry.setTag('user_email', data?.authUser.email);
    Sentry.setTag('user_name', data?.storedUser.username);
    Sentry.setTag('user_company_id', data?.storedUser.companyId);
    Sentry.setTag('user_company_name', data?.company.companyName);
    Sentry.setTag('user_language', data?.storedUser.language);
  }, [data]);
};
